import React, { Component } from 'react';
import filter  from 'lodash/filter';
import findIndex  from 'lodash/findIndex';
import take  from 'lodash/take';
import LocationSearchBox from '../common/LocationSearchBox';
import Autocomplete from 'react-autocomplete/build/lib/Autocomplete.js';
import { queryStringtoArray, removeParameterFromUrl } from '../../helpers/index';

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadySearched: false,
      autoSuggestedList: [],
      searchDropdown: false,
      setCenter: false,
      mapLocationChanged: false,
      showLocationModal: false,
      locationSearch: [{
        lat: 0,
        lng: 0,
        pinImage: ''
      }],
      showResults: false,
      seachedValue: "",
      seachedResult: [],
      location: "",
      addLocationModal: false,
      editLocationModal: false,
      showLocationDropdown: false,
      pickLocationModal: false
    };
    this.searchVendors = this.searchVendors.bind(this);
  }

  componentWillMount() {
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : undefined;
    if (userSelectedLocation) {
      this.props.actions.setCurrentLocation(userSelectedLocation);
    }
    if (!(this.props.commonReducer.autoSuggestedList && this.props.commonReducer.autoSuggestedList.length)) {
      this.props.actions.getAutosuggestedList();
    }
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.commonReducer && nextProps.commonReducer.userLocationText) {
    //   this.setState({
    //     location: nextProps.commonReducer.userLocationText
    //   });
    // }
    if (nextProps.commonReducer && nextProps.commonReducer.autoSuggestedList) {
      this.setState({ autoSuggestedList: nextProps.commonReducer.autoSuggestedList });
    }
    if (!this.state.alreadySearched) {
      if (queryStringtoArray('q')) {
        this.searchVendors(queryStringtoArray('q'), 'keyword');
        this.setState({ alreadySearched: true, seachedValue: decodeURI(queryStringtoArray('q')) });
      } else {
        // this.searchVendors('', 'keyword');
        const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : undefined;
        let searchLocation = userSelectedLocation;
        if (searchLocation == undefined) {
          searchLocation = this.props.commonReducer.userLocation;
        } else {
          searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng }
        }
        if (searchLocation && searchLocation.latitude && searchLocation.longitude && (queryStringtoArray('lat'))) {
          this.props.actions.searchVendors(false, false, searchLocation, 'distance');
        }
        this.setState({ alreadySearched: true });
      }
    }

  }

  handleFocus() {
    this.setState({
      showResults: true
    });
  }

  handleblur() {
    this.setState({
      showResults: false
    });
  }

  seachedValue(e) {
    this.setState({ seachedValue: e })
  }

  dropdownSelect(e) {
    this.setState({ location: e })
  }

  searchVendors(data, type) {
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : undefined;
    let searchLocation = userSelectedLocation;
    if (searchLocation == undefined) {
      searchLocation = this.props.commonReducer.userLocation;
    } else {
      searchLocation = { latitude: userSelectedLocation.lat, longitude: userSelectedLocation.lng }
    }
    searchLocation = searchLocation && searchLocation.latitude && searchLocation.longitude ? searchLocation : undefined;
    if (type == "keyword") {
      if (data) {
        let index = findIndex(this.state.autoSuggestedList, { name: data });
        if (index != -1) {
          this.searchVendors(this.state.autoSuggestedList[index], 'autosuggest');
        } else {
          this.props.actions.searchVendors(type, data, searchLocation, "distance");
        }
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, "distance");
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    } else if (type == "autosuggest") {
      if (data) {
        this.props.actions.searchVendors(type, data, searchLocation, "distance");
      } else if (searchLocation && searchLocation.latitude && searchLocation.longitude) {
        this.props.actions.searchVendors(false, false, searchLocation, "distance");
      } else {
        let href = window.location.href;
        let updatedUrl = removeParameterFromUrl(href, 'q');
        // window.location.href = updatedUrl;
        window.history.replaceState(null, null, updatedUrl);
      }
    }
  }

  render() {
    const { commonReducer, actions } = this.props;
    const style = {
      textBold: {
        fontFamily: "CenturyGothic_bold",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, textNormal: {
        fontFamily: "CenturyGothic",
        display: "inline",
        fontSize: "13px",
        color: '#717982',
      }, dropdownLeftCss: {
        padding: 'none',
        borderTop: 'none',
        maxHeight: this.props.mobile ? '500px' : '250px',
        overflow: 'auto',
        border: this.props.mobile ? "none" : "",
        top: this.props.mobile ? "50px" : "auto",
        zIndex: "999",
        position: this.props.mobile ? "absolute" : "",
      },
      dropdownRightCss: {
        padding: 'none',
        border: '1px solid #DAE1E7',
        borderTop: 'none',
      }
    };

    let searchView = filter(this.state.autoSuggestedList, (val) => {
      if (this.state.seachedValue != "" && val.name.toLowerCase().indexOf(this.state.seachedValue.toLowerCase()) != -1) {
        return val.name;
      }
    });
    searchView = take(searchView, 5);
    const userSelectedLocation = localStorage.getItem('userSelectedLocation') ? JSON.parse(localStorage.getItem('userSelectedLocation')) : {};
    return (
      <div className="searchBar">
        <LocationSearchBox style={style} defaultSearchTerm={userSelectedLocation && userSelectedLocation.label} fromSearchBar={true} commonReducer={commonReducer} actions={actions} selectedLocation={(location) => { this.props.actions.setCurrentLocation(location) }} />
        <div className={searchView.length > 0 ? "searchFill active" : "searchFill"}>
          <Autocomplete
            getItemValue={(item) => item.name}
            items={searchView}
            renderItem={(item, isHighlighted) =>
              <div className={isHighlighted ? "search-menu highlight" : "search-menu"} >
                <span>
                  <span style={style.textNormal}>{item.name.substring(0, item.name.toLocaleLowerCase().indexOf(this.state.seachedValue.toLocaleLowerCase()))}</span>
                  <span style={style.textBold}>{item.name.substring(item.name.toLocaleLowerCase().indexOf(this.state.seachedValue.toLocaleLowerCase()), item.name.toLocaleLowerCase().indexOf(this.state.seachedValue.toLocaleLowerCase()) + this.state.seachedValue.length)}</span>
                  <span style={style.textNormal}>{item.name.substring(item.name.toLocaleLowerCase().indexOf(this.state.seachedValue.toLocaleLowerCase()) + this.state.seachedValue.length)}</span>
                </span>
              </div>
            }
            renderInput={(props) => {
              return (<form onSubmit={(e) => {
                e.preventDefault();
                this.searchVendors(this.state.seachedValue, 'keyword');
              }}>
                <input {...props} />
              </form>);
            }}
            value={this.state.seachedValue}
            onChange={(e) => this.setState({ seachedValue: e.target.value })}
            onSelect={(val, obj) => { this.setState({ seachedValue: val }); this.searchVendors(obj, 'autosuggest'); }}
            open={this.state.seachedValue != "" ? this.state.searchDropdown : false}
            onMenuVisibilityChange={(e) => this.setState({ searchDropdown: e })}
            menuStyle={style.dropdownRightCss}
            inputProps={{ placeholder: "Find a quality service provider near you." }}
          />
          <i className="mdi mdi-magnify" onClick={() => { this.searchVendors(this.state.seachedValue, 'keyword'); }} />
        </div>
      </div>
    );
  }
}
