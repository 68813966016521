import React, { Component } from 'react';
import { find } from 'lodash';
import FaqAndCategories from './FaqAndCategories';
import FaqQuestion from './FaqQuestion';
import Header from '../common/Header';
import LoginHeader from '../common/LoginHeader';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import { appBasePath, imageBasePath } from '../../constants/staticData';
import Footer from '../Landing/Footer';

class Faq extends Component {
  constructor() {
    super();
    this.state = {
      searchMobile: false,
      notificationVisible: '',
      messageVisible: '',
      navActive: 'customer'
    };
    this.state = {
      activeQuestion: null,
      topFaq: [
        {
          id: 0,
          question: 'How can I check my order status?',
          answers: "If you have an Airbnb coupon code you want to use towards a new reservation, you can add it on the checkout page. Coupon codes can't be applied to existing reservations or experiences.How can I cancel my order? \n To use your coupon:"
        },
        { id: 1, question: 'How can I cancel my order?', answers: 'How can I cancel my order?' },
        { id: 2, question: 'How can I return replace an item?', answers: 'How can I cancel my order?' },
        {
          id: 3,
          question: 'When can I expect refund for my returned item?',
          answers: 'How can I cancel my order?'
        },
        {
          id: 4,
          question: 'What are the different modes of payment available?',
          answers: 'How can I cancel my order?'
        },
        {
          id: 5,
          question: 'I dont remember my password. Help! What is SD+?',
          answers: 'How can I cancel my order?'
        }
      ],
      categories: ['Accounts', 'Verfication', 'Service Request', 'Payment', 'Price and Fees']
    };
  }

  toggleNotification(isVisible) {
    this.setState({ notificationVisible: isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ messageVisible: isVisible });
  }
  selectesQuestionFunc(questionKey) {
    let selectedQues = find(this.state.topFaq, { id: questionKey });
    this.setState({ activeQuestion: selectedQues });
  }
  reset() {
    this.setState({ activeQuestion: null });
  }
  render() {
    const { router, actions, commonReducer } = this.props;
    
    return (
      <div className="faq-body">
        {localStorage.getItem('authData')
          ? <Header
            hideOption={() => this.setState({ searchMobile: true })}
            commonReducer={commonReducer}
            notificationCount={2}
            profileName="Derrick Frank"
            notificationCallBack={this.toggleNotification}
            messageCallBack={this.toggleMessage.bind(this)}
            router={router}
            actions={actions}
            authReducer={this.props.authReducer}
            metaTagKey={'faq'}
          />
          : <LoginHeader
            hideOption={() => this.setState({ searchMobile: true })}
            pageType="signIn"
            headerClickAction={e => {
              e.preventDefault();
              router.push(appBasePath + '/register');
            }}
            metaTagKey={'faq'}
          />}

        <div className="faq-banner">
          <div className="faq-img">
            <img src={`${imageBasePath}/images/faqBanner.png`} />
          </div>
          <div className="faq-desc">
            <h1> FAQs </h1>
            <p> Questions? You’re in the right place. </p>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Search…" />
              <div className="form-icon">
                <i className="mdi mdi-magnify" />
              </div>
            </div>
          </div>
        </div>
        <div className="faq-content">
          <div className="row">
            <div className="left-block col-md-4">
              <ul className="n-nav-selection">
                <li
                  clasName={this.state.navActive == 'customer' ? 'active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ navActive: 'customer' });
                  }}
                >
                  Customer
                                </li>
                <li
                  clasName={this.state.navActive == 'business' ? 'active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ navActive: 'business' });
                  }}
                >
                  Business
                                </li>
              </ul>
            </div>
            <div className="right-block col-md-8">
              {!this.state.activeQuestion &&
                <FaqAndCategories
                  topFaq={this.state.topFaq}
                  categories={this.state.categories}
                  selectQuestion={this.selectesQuestionFunc.bind(this)}
                />}
              {this.state.activeQuestion &&
                <span>
                  <FaqQuestion question={this.state.activeQuestion} back={this.reset.bind(this)} />
                  <span className="helpful-text">
                    Was this article helpful?
                                        {' '}
                    <span className="link">Yes</span>
                    {' '}
                    or
                                        {' '}
                    <span className="link">No</span>
                    {' '}
                  </span>
                </span>}
            </div>
          </div>

        </div>
       <Footer {...this.props} />
        {this.state.searchMobile &&
          <div>
            <MobileHeaderSearch
              mobile={true}
              actions={this.props.actions}
              commonReducer={this.props.commonReducer}
              mobSearchClick={() => this.setState({ searchMobile: false })}
            />
          </div>}
      </div>
    );
  }
}
export default Faq;
