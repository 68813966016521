import * as types from '../actions/actionTypes';
import { assign, cloneDeep, findIndex, remove, map, concat } from 'lodash';
const initialState = {
  getAllServices: [],
  bookingRequests: {},
  batchIdList: {},
  openBookingRequests: [],
  completedBookingRequests: [],
  closedBookingRequests: [],
  dashboardJobStatus: [],
  openPageInfo: {},
  closedPageInfo: {},
  completedPageInfo: {},
  nextPageInClosedJobs: 1,
  nextPageInOpenJobs: 1,
  nextPageInCompletedJobs: 1,
  createRequestImageArray: [],
  createRequestFileArray: [],
  editRequestImageArray: [],
  editRequestFileArray: [],
  packageSelected: {},
  carSelected:{}
};

export default function bookingRequestReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_BOOKING_REQUESTS:
      {
        if (action.unableToFetch) {
          return (assign({}, state, {
            isLoaded: true,
          }));
        } else {
          let a = cloneDeep(action.bookingRequests);
          let bookingRequests = [];
          if (action.requestType == 'open') {
            let b = cloneDeep(state.openBookingRequests);
            bookingRequests = action.fromLoadMore ? concat(b, a) : a;
          } else if (action.requestType == 'closed') {
            let b = cloneDeep(state.closedBookingRequests);
            bookingRequests = action.fromLoadMore ? concat(b, a) : a;
          }  else if (action.requestType == 'completed') {

          let b = cloneDeep(state.completedBookingRequests);
          bookingRequests = action.fromLoadMore ? concat(b, a) : a;
        }

          return (assign({}, state, {
            openBookingRequests: action.requestType == 'open' ? bookingRequests : state.openBookingRequests,
            closedBookingRequests: action.requestType == 'closed' ? bookingRequests : state.closedBookingRequests,
            completedBookingRequests: action.requestType == 'completed' ? bookingRequests : state.completedBookingRequests,
            dashboardJobStatus: action.requestType == 'all' ? action.bookingRequests : state.dashboardJobStatus,
            openPageInfo: action.requestType == 'open' ? action.pageInfo : state.openPageInfo,
            closedPageInfo: action.requestType == 'closed' ? action.pageInfo : state.closedPageInfo,
            completedPageInfo: action.requestType == 'completed' ? action.pageInfo : state.completedPageInfo,
            nextPageInClosedJobs: action.requestType == 'closed' ? action.currentPage + a.length : state.nextPageInClosedJobs,
            nextPageInOpenJobs: action.requestType == 'open' ? action.currentPage + a.length : state.nextPageInOpenJobs,
            nextPageInCompletedJobs: action.requestType == 'completed' ? action.currentPage + a.length : state.nextPageInCompletedJobs,
            isLoaded: true,
            currentComponentKey: '',
            err_userpreffereddatetime: ''
          }));
        }
      }

    case types.GET_BOOKING_REQUESTS_DETAILS: {

      const bookingRequests = cloneDeep(state.bookingRequests);
      if (bookingRequests[action.requestId] && bookingRequests[action.requestId].batch_id) {
        action.bookingRequestData.batch_id = bookingRequests[action.requestId].batch_id;
      }
      bookingRequests[action.requestId] = action.bookingRequestData;
      console.log("bookingRequests = ", bookingRequests[action.requestId]);
      return assign({}, state, {
        isLoaded: true,
        bookingRequests: bookingRequests,
        currentComponentKey: '',
        err_userpreffereddatetime: '',
        garagejobquotesdetail: bookingRequests[action.requestId].garagejobquotes

      });
    }

    case types.CREATE_BATCH_ID:
      {
        const batchIdList = { ...state.batchIdList };
        batchIdList[action.batch_id] = {
          totalCount: 0,
          successCount: 0,
          failureCount: 0,
        };
        return {
          ...state,
          batchIdList: batchIdList,
        };
      }

    case types.INCREAMENT_POLLCOUNT:
      {
        let batchIdObj = { ...state.batchIdList[action.batch_id] };
        if (batchIdObj) {
          batchIdObj.pollCount = batchIdObj.pollCount + 1;
        }
        const batchIdList = { ...state.batchIdList };
        batchIdList[action.batch_id] = batchIdObj;
        return {
          ...state,
          batchIdList: batchIdList
        };
      }

    case types.SUCCESS_UPLOAD_IMAGES: {
      let batchIdObj = { ...state.batchIdList[action.batch_id] };
      let createRequestImage = cloneDeep(state.createRequestImageArray);
      let createRequestFile = cloneDeep(state.createRequestFileArray);
      let editRequestImage = cloneDeep(state.editRequestImageArray);
      let editRequestFile = cloneDeep(state.editRequestFileArray);
      if (batchIdObj) {
        batchIdObj.totalCount = batchIdObj.totalCount + 1;
        batchIdObj.successCount = batchIdObj.successCount + 1;
      }
      if (createRequestImage && action.imgData && action.fromAction == "createrequest") {
        createRequestImage.push(action.imgData);
      } else if (editRequestImage && action.imgData && action.fromAction == "editrequest") {
        editRequestImage.push(action.imgData);
      }
      if (createRequestFile && action.fileData && action.fromAction == "createrequest") {
        createRequestFile.push(action.fileData);
      } else if (editRequestFile && action.fileData && action.fromAction == "editrequest") {
        editRequestFile.push(action.fileData);
      }
      const batchIdList = { ...state.batchIdList };
      batchIdList[action.batch_id] = batchIdObj;
      return {
        ...state,
        batchIdList: batchIdList,
        createRequestImageArray: createRequestImage,
        createRequestFileArray: createRequestFile,
        editRequestImageArray: editRequestImage,
        editRequestFileArray: editRequestFile
      };
    }

    case types.SUCCESS_DELETE_IMAGES: {
      let createRequestImage = cloneDeep(state.createRequestImageArray);
      let editRequestImage = cloneDeep(state.editRequestImageArray);
      if (action.fromAction == "createrequest") {
        createRequestImage = remove(createRequestImage, (n) => {
          return n.id != action.id;
        });
      } else if (action.fromAction == "editrequest") {
        editRequestImage = remove(editRequestImage, (n) => {
          return n.id != action.id;
        });
      }
      return (assign({}, state, {
        createRequestImageArray: createRequestImage,
        editRequestImageArray: editRequestImage
      }));
    }

    case types.SUCCESS_DELETE_FILES: {
      let createRequestFile = cloneDeep(state.createRequestFileArray);
      let editRequestFile = cloneDeep(state.editRequestFileArray);
      if (action.fromAction == "createrequest") {
        createRequestFile = remove(createRequestFile, (n) => {
          return n.id != action.id;
        });
      } else if (action.fromAction == "editrequest") {
        editRequestFile = remove(editRequestFile, (n) => {
          return n.id != action.id;
        });
      }
      return (assign({}, state, {
        createRequestFileArray: createRequestFile,
        editRequestFileArray: editRequestFile
      }));
    }

    case types.CANCEL_USER_BOOKING_REQUEST: {
      return (assign({}, state, {
        currentComponentKey: '/request',
        err_userpreffereddatetime: ''
      }));
    }

    case types.FAILURE_UPLOAD_IMAGES: {
      let batchIdObj = { ...state.batchIdList[action.batch_id] };
      if (batchIdObj) {
        batchIdObj.totalCount = batchIdObj.totalCount + 1;
        batchIdObj.failureCount = batchIdObj.failureCount + 1;
      }
      const batchIdList = { ...state.batchIdList };
      batchIdList[action.batch_id] = batchIdObj;
      return {
        ...state,
        batchIdList: batchIdList
      };
    }

    case types.CLEAR_IMAGES:
      return {
        ...state,
        createRequestImageArray: [],
        createRequestFileArray: [],
        editRequestImageArray: [],
        editRequestFileArray: [],
      };

    case types.GET_ALL_SERVICES:
      return {
        ...state,
        getAllServices: action.getAllServices,
        washCategoryData: action.getAllServices && action.getAllServices.length > 0 && action.getAllServices[0].sub_services,
        repairCategoryData: action.getAllServices && action.getAllServices.length > 0 && action.getAllServices[2].sub_services,
        serviceCategoryData: action.getAllServices && action.getAllServices.length > 0 && action.getAllServices[1].sub_services,
        selecetedCarProfileId: action.selecetedCarProfileId,
        err_userpreffereddatetime: '',
        servicesFetched: action.servicesFetched
      };

    case types.CREATE_BOOKING_REQUEST: {
      let bookingRequests = cloneDeep(state.bookingRequests);
      let batch_id = {
        batch_id: action.batch_id
      };
      bookingRequests[action.requestid] = batch_id;


      return (assign({}, state, {
        currentComponentKey: `/request/${action.requestid}`,
        err_userpreffereddatetime: '',
        bookingRequests: bookingRequests,
        garagejobquotesdetail: []
      }));
    }

    case types.EDIT_BOOKING_REQUEST: {
      return (assign({}, state, {
        currentComponentKey: `/request/${action.requestid}`,
        err_userpreffereddatetime: '',
      }));
    }

    case types.USER_PREFEREDTIME_ERROR: {
      return (assign({}, state, {
        err_userpreffereddatetime: action.err_userpreffereddatetime,
      }));
    }

    case types.GET_VENDOR_QUOTES_LIST: {
      let bookingRequests = cloneDeep(state.bookingRequests);
      let vendorQuotesList = action.vendorQuotesList;
      // let garagejobquotes = keyBy(vendorQuotesList.garagejobquotes, 'id');
      let garagejobquotes = vendorQuotesList.garagejobquotes;
      vendorQuotesList.garagejobquotes = garagejobquotes;
      if (bookingRequests[action.requestId]) {
        bookingRequests[action.requestId].vendorQuotesList = vendorQuotesList;
      }
      return assign({}, state, { bookingRequests: bookingRequests, currentComponentKey: '', garagejobquotesdetail: [] });
    }

    case types.GET_QUOTES_PRICING_DETAILS: {
      let bookingRequests = cloneDeep(state.bookingRequests);
      let vendorQuotesList = cloneDeep(bookingRequests[action.requestId] && bookingRequests[action.requestId].vendorQuotesList);
      let garagejobquotesdetail = action.quotesDetails && action.quotesDetails && action.quotesDetails.garagejobquotes;
      let index = findIndex(garagejobquotesdetail, { id: action.jobId });
      let vendorIndex = findIndex(vendorQuotesList, { id: action.jobId });
      if (vendorQuotesList && vendorQuotesList.garagejobquotes && vendorQuotesList.garagejobquotes.length && vendorQuotesList.garagejobquotes[vendorIndex] && index != -1) {
        let garage = vendorQuotesList.garagejobquotes[vendorIndex].garage;
        vendorQuotesList.garagejobquotes[vendorIndex] = garagejobquotesdetail[index];
        vendorQuotesList.garagejobquotes[vendorIndex].garage = garage;
        bookingRequests[action.requestId].vendorQuotesList = vendorQuotesList;
      }
      return assign({}, state, { bookingRequests: bookingRequests, currentComponentKey: '', garagejobquotesdetail: garagejobquotesdetail});
    }

    case types.ACCEPT_QUOTES: {
      return assign({}, state, { currentComponentKey: '', hideButtonLoader: true });
    }

    case types.MAKE_PAYMENT: {
      return assign({}, state, { paymentUrl: action.url,method: action.method, status: action.status, hideButtonLoader: true });
    }

    case types.PAYMENT_STATUS: {
      return assign({}, state, { paymentData: action.paymentData, hideButtonLoader: true });
    }

    case types.CLEAR_COMPONENT_KEY: {
      return assign({}, state, { currentComponentKey: '', isLoaded: false, err_userpreffereddatetime: '' });
    }

    case types.GET_ALL_CANCEL_REASONS: {
      return {
        ...state,
        getAllCancelReasons: action.getAllCancelReasons,
        currentComponentKey: ''
      };
    }
    case types.GET_FAVOURITE_VENDORS: {
      if (action.unableToFetch) {
        return assign({}, state, { currentComponentKey: '', favouriteVendorsLoaded: true, favouriteVendorsList: [] });
      } else {
        return assign({}, state, { currentComponentKey: '', favouriteVendorsLoaded: true, favouriteVendorsList: action.favouriteVendorsList });
      }
    }
    case types.CHANGE_FAVOURITE_SELECTION: {
      if (action.source == 'favouriteSection') {
        let favouriteVendorsList = cloneDeep(state.favouriteVendorsList);
        remove(favouriteVendorsList, function (vendor) {
          return vendor.id == action.vendorId;
        });
        return assign({}, state, { currentComponentKey: '', favouriteVendorsList: favouriteVendorsList });
      } else if (action.source == 'activeVendors') {
        let bookingRequests = cloneDeep(state.bookingRequests);
        let vendorQuotesList = cloneDeep(bookingRequests[action.requestId].vendorQuotesList);
        map(vendorQuotesList.garagejobquotes, (value, key) => {
          let garagejobquotes = { ...value };
          if (value.garage && value.garage.id == action.vendorId) {
            garagejobquotes.garage.isfavourite = action.status;
          }
          vendorQuotesList[key] = garagejobquotes;
        });
        bookingRequests[action.requestId].vendorQuotesList = vendorQuotesList;
        return assign({}, state, { currentComponentKey: '', bookingRequests: bookingRequests });
      } else {
        return assign({}, state, { currentComponentKey: '' });
      }
    }
    case types.SUBMIT_RATING_REVIEW_FOR_JOB:
      {
        const bookingRequests = cloneDeep(state.bookingRequests);
        const dashboardJobStatus = cloneDeep(state.dashboardJobStatus);
        const closedBookingRequests = cloneDeep(state.closedBookingRequests);
        const openBookingRequests = cloneDeep(state.openBookingRequests);
        if (action.source && action.requestBody) {
          if (bookingRequests[action.userBookingRequestid] && bookingRequests[action.userBookingRequestid].garagejobquotes && bookingRequests[action.userBookingRequestid].garagejobquotes.length
            && bookingRequests[action.userBookingRequestid].garagejobquotes[0]) {
            bookingRequests[action.userBookingRequestid].garagejobquotes[0].garagereview = [{
              comments: action.requestBody.comments ? action.requestBody.comments : '',
              jobId: bookingRequests[action.userBookingRequestid].garagejobquotes[0].id,
              rating: action.requestBody.rating ? action.requestBody.rating : 0
            }];
          }
          let index = findIndex(closedBookingRequests, { 'id': action.requestBody.job_id });
          if (index != -1) {
            if (closedBookingRequests[action.userBookingRequestid] && closedBookingRequests[action.userBookingRequestid].garagejobquotes && closedBookingRequests[action.userBookingRequestid].garagejobquotes.length
              && closedBookingRequests[action.userBookingRequestid].garagejobquotes[0]) {
              closedBookingRequests[action.userBookingRequestid].garagejobquotes[0].garagereview = [{
                comments: action.requestBody.comments ? action.requestBody.comments : '',
                jobId: closedBookingRequests[action.userBookingRequestid].garagejobquotes[0].id,
                rating: action.requestBody.rating ? action.requestBody.rating : 0
              }];
            }
          }
          index = findIndex(dashboardJobStatus, { 'id': action.requestBody.job_id });
          if (index != -1) {
            if (dashboardJobStatus[action.userBookingRequestid] && dashboardJobStatus[action.userBookingRequestid].garagejobquotes && closedBookingRequests[action.userBookingRequestid].garagejobquotes.length
              && dashboardJobStatus[action.userBookingRequestid].garagejobquotes[0]) {
              dashboardJobStatus[action.userBookingRequestid].garagejobquotes[0].garagereview = [{
                comments: action.requestBody.comments ? action.requestBody.comments : '',
                jobId: dashboardJobStatus[action.userBookingRequestid].garagejobquotes[0].id,
                rating: action.requestBody.rating ? action.requestBody.rating : 0
              }];
            }
          }
          return assign({}, state, {
            hideButtonLoader: true,
            bookingRequests: bookingRequests,
            dashboardJobStatus: dashboardJobStatus,
            openBookingRequests: openBookingRequests,
            closedBookingRequests: closedBookingRequests,
            garagejobquotesdetail: []
          });
        } else {
          return assign({}, state, { hideButtonLoader: true });
        }
      }
    case types.SHOW_TOAST_MESSAGE: {
      return assign({}, state, { toastMessage: action.toastMessage, toastType: action.toastType })
    }
    case types.PLUS_PACKAGE_SELECTED: {
      return assign({}, state, { packageSelected: action.data })
    }
    case types.PLUS_CAR_SELECTED: {
      return assign({}, state, { carSelected: action.data })
    }

    case types.HIDE_TOAST_MESSAGE: {
      return assign({}, state, { toastMessage: '', toastType: '' });
    }
    case types.HIDE_BUTTON_LOADER: {
      return assign({}, state, { hideButtonLoader: false });
    }
    case types.GET_JOB_STATUS: {
      const bookingRequests = cloneDeep(state.bookingRequests);
      const dashboardJobStatus = cloneDeep(state.dashboardJobStatus);
      const closedBookingRequests = cloneDeep(state.closedBookingRequests);
      const openBookingRequests = cloneDeep(state.openBookingRequests);
      let bookingRequestData = cloneDeep(bookingRequests[action.requestId]);
      if (action.source == 'requestview' && bookingRequestData) {
        if (!action.unableToFetch) { bookingRequestData.jobStatus = action.jobStatus; }
        bookingRequestData.isJobStatusLoaded = true;
        bookingRequests[action.requestId] = bookingRequestData;
      } else if (action.source == 'open') {
        let index = findIndex(openBookingRequests, { 'id': action.jobId });
        if (index != -1) {
          if (!action.unableToFetch) { openBookingRequests[index].jobStatus = action.jobStatus; }
          openBookingRequests[index].isJobStatusLoaded = true;
        }
      } else if (action.source == 'closed') {
        let index = findIndex(closedBookingRequests, { 'id': action.jobId });
        if (index != -1) {
          if (!action.unableToFetch) { closedBookingRequests[index].jobStatus = action.jobStatus; }
          closedBookingRequests[index].isJobStatusLoaded = true;
        }
      } else if (action.source == 'dashboard') {
        let index = findIndex(dashboardJobStatus, { 'id': action.jobId });
        if (index != -1) {
          if (!action.unableToFetch) { dashboardJobStatus[index].jobStatus = action.jobStatus; }
          dashboardJobStatus[index].isJobStatusLoaded = true;
        }
      }
      return assign({}, state, {
        bookingRequests: bookingRequests,
        dashboardJobStatus: dashboardJobStatus,
        openBookingRequests: openBookingRequests,
        closedBookingRequests: closedBookingRequests,
        garagejobquotesdetail: []
      });
    }
    case types.UPDATE_BOOKING_REQUEST_NOTIFICATION: {
      if (action.unableToFetch) {
        return state;
      } else {
        let openBookingRequests = cloneDeep(state.openBookingRequests);
        let closedBookingRequests = cloneDeep(state.closedBookingRequests);
        let bookingRequests = cloneDeep(state.bookingRequests);
        let dashboardJobStatus = cloneDeep(state.dashboardJobStatus);
        let openPageInfo = cloneDeep(state.openPageInfo);
        let closedPageInfo = cloneDeep(state.closedPageInfo);
        if (
          action.notificationType == "New Job Quotes" ||
          action.notificationType == "Job Accepted" ||
          action.notificationType == "Request Completed" ||
          action.notificationType == "Request Expired" ||
          action.notificationType == "Request Cancel" ||
          action.notificationType == "Service Status Update" ||
          action.notificationType == "Pay At Shop Confirmed" ||
          action.notificationType == "New Package Added" ||
          action.notificationType == "Confirmed Job Decline") {

          let bookingRequestData = cloneDeep(bookingRequests[action.requestId]);
          bookingRequestData = bookingRequestData ? bookingRequestData : {};
          bookingRequests[action.requestId] = assign({}, bookingRequestData, action.bookingRequestData);

          let openbookingRequestIndex = findIndex(openBookingRequests, { requestid: action.requestId });
          if (openbookingRequestIndex != -1) {
            openBookingRequests[openbookingRequestIndex] = action.bookingRequestData;
          }

          let closedBookingRequestsIndex = findIndex(closedBookingRequests, { requestid: action.requestId });
          if (closedBookingRequestsIndex != -1) {
            closedBookingRequests[closedBookingRequestsIndex] = action.bookingRequestData;
          } else {
            if (
              action.notificationType == "Request Completed" ||
              action.notificationType == "Service Status Update" ||
              action.notificationType == "Request Expired" ||
              action.notificationType == "Request Cancel" ||
              action.notificationType == "Confirmed Job Decline") {
              closedBookingRequests[action.requestId] = assign({}, bookingRequestData, action.bookingRequestData);
            }
          }

          if (
            action.notificationType == "Request Completed" ||
            action.notificationType == "Service Status Update" ||
            action.notificationType == "Request Expired" ||
            action.notificationType == "Request Cancel" ||
            action.notificationType == "Confirmed Job Decline") {
            let openbookingRequestIndex = findIndex(openBookingRequests, { requestid: action.requestId });
            if (openbookingRequestIndex != -1) {
              remove(openBookingRequests, (request) => {
                return request.requestid == action.requestId;
              });
            }
          }

          let dashboardJobStatusIndex = findIndex(dashboardJobStatus, { requestid: action.requestId });
          if (dashboardJobStatusIndex != -1) {
            dashboardJobStatus[dashboardJobStatusIndex] = action.bookingRequestData;
          }

        }
        return {
          ...state,
          openBookingRequests: openBookingRequests,
          closedBookingRequests: closedBookingRequests,
          dashboardJobStatus: dashboardJobStatus,
          openPageInfo: openPageInfo,
          closedPageInfo: closedPageInfo,
          bookingRequests: bookingRequests
        };
      }
    }
    default:
      return state;
  }
}
