import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import ContactDetails from './ContactDetails.jsx';
import ChangePassword from './ChangePassword.jsx';
import NotificationComponent from './NotificationComponent.jsx';
import MobileHeaderSearch from '../common/MobileHeaderSearch'

export default class UserAccounts extends Component {
  constructor(props) {
    super(props)
    this.changedOptions = this.changedOptions.bind(this);
    this.state = {
      searchMobile: false,
      valueChanged: '1',
      authonticationValue: JSON.parse(localStorage.getItem('authData'))
    };
  }



  toggleNotification(isVisible) {
    // this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  changedOptions(selectedata) {
    this.setState({ 'valueChanged': selectedata });
    //alert(selectedata);
  }

  render() {
    const { authReducer, useraccountReducer, commonReducer } = this.props;
    return (
      <div>
        {/*Header*/}
        <Header hideOption={() => this.setState({ searchMobile: true })} commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={this.props.router} actions={this.props.actions} authReducer={this.props.authReducer} />
        <div className="main-wrapper customer-setting-back">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header page-sec-header-settings page-sec-header-mob">
            <div className="padwrapper-account-header account-header">
              <h4>Account</h4>
            </div>
          </div>
          <div className="my-account-body my-account-body-mob">
            <div className="container-fluid navigation-header">
              <div className="row clearfix">
                <div className={"col-xs-4 navigation-header-tab " + (this.state.valueChanged === '1' ? 'active' : '')} onClick={() => { this.changedOptions('1'); }}>
                  <span>CONTACT DETAILS</span>
                </div>
                <div className={"col-xs-4 navigation-header-tab " + (this.state.valueChanged === '2' ? 'active' : '')}
                  onClick={() => { this.changedOptions('2'); }}>
                  <span>CHANGE PASSWORD</span>
                </div>
                {/* <div className={"col-xs-3 navigation-header-tab " + (this.state.valueChanged === '3' ? 'active' : '')} onClick={() => {this.changedOptions('3');}}>
                              <span>PAYMENTS</span>
                           </div> */}
                <div className={"col-xs-4 navigation-header-tab " + (this.state.valueChanged === '4' ? 'active' : '')} onClick={() => { this.changedOptions('4'); }}>
                  <span>NOTIFICATION</span>
                </div>
              </div>
            </div>
            <div className="navigation-bady-part">
              {this.state.valueChanged === '1' &&
                <ContactDetails authData={this.state.authonticationValue}
                  authReducer={this.props.authReducer}
                  actions={this.props.actions} router={this.props.router} />
              }
              {this.state.valueChanged === '2' &&
                <ChangePassword
                  authReducer={this.props.authReducer}
                  actions={this.props.actions} />
              }
              {/* { this.state.valueChanged === '3' &&
                             <PackagesComponent
                             actions={this.props.actions}/>
                           } */}
              {this.state.valueChanged === '4' &&
                <NotificationComponent
                  actions={this.props.actions}
                  authReducer={this.props.authReducer}
                  userReducer={this.props.useraccountReducer} />
              }


            </div>
          </div>
        </div>
        <div className="footerSection" >
          {/*AppLink
                    <AppLink />*/}
          {/*Footer*/}
          <Footer {...this.props} />
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
        </div>}
      </div>
    );
  }
}
