import React, { Component } from "react";
import CarouselSlider from "../common/CarouselSlider";
import LoginHeader from "../common/LoginHeader";
import Button from "../common/Button";
import TextInput from "../common/TextInput";
import AlertDismissable from "../common/AlertDismissable";
import CustomScroll from "react-custom-scroll";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { validateFormSubmit } from "../../helpers";
import {
  validationMessage,
  googleClientId,
  fbAppId,
  imageBasePath,
  appBasePath
} from "../../constants/staticData";
import { has } from "lodash";
import TagManager from "react-gtm-module";

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionError: false,
      isButtonLoading: false
    };
    this.initialFormData = {
      email: "",
      password: ""
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      email: false,
      password: false
    };
    this.onFieldBlur = this.onFieldBlur.bind(this);
  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }
  componentWillMount() {
    const authData = localStorage.getItem("authData")
      ? JSON.parse(localStorage.getItem("authData"))
      : "";
    const userId = localStorage.getItem("userId");
    if (
      userId &&
      authData &&
      authData.phone &&
      authData.phoneVerified &&
      authData.type == "customer"
    ) {
      this.props.router.push(`${appBasePath}/dashboard-plus`);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.authReducer.hideButtonLoader && this.state.isButtonLoading) {
      this.setState({ isButtonLoading: false });
    }
    const authData = localStorage.getItem("authData")
      ? JSON.parse(localStorage.getItem("authData"))
      : "";
    const userId = localStorage.getItem("userId");
    if (
      userId &&
      authData &&
      authData.phone &&
      authData.phoneVerified &&
      authData.type == "customer"
    ) {
      if (!this.props.authReducer.redirectUrl) {
        this.props.router.push(`${appBasePath}/dashboard-plus`);
      }
    }
  }
  componentWillUnmount() {
    this.props.actions.hideErrorMessage();
  }
  onKeyPress(e){
    let key=e.keyCode || e.which;
    if(key==13){
      this.signInAction(e);
    }
  }

  signInAction(e) {
    e.preventDefault();
    const { router } = this.props;
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;
    if (!validatedForm.validForm) {
      this.setState({ submissionError: true });
      return;
    } else {
      this.setState({ submissionError: false, isButtonLoading: true });
      this.props.actions.signInAction({
        email: this.formData.email,
        password: this.formData.password,
        usertype: "customer"
      });
    }
  }

  render() {

    const tagManagerArgs = {
      dataLayer: {
        pageCategory: "Normal Sign In",
      },
      dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs);


     const { router, authReducer } = this.props;
    return (
      <div className="container-fluid" id="wrapper">
        <LoginHeader
          pageType="signIn"
          headerClickAction={e => {
            e.preventDefault();
            router.push(`${appBasePath}/sign-up`);
          }}
          metaTagKey={"sign-in"}
        />
        <CarouselSlider
          pageType="signIn"
          sliderImage={`${imageBasePath}/images/customer-log-in-carcility.png`}
          sliderImageAlt="Customer login carcility"
        />
        <div className="col-md-6 col-sm-12 col-xs-12 pad0 grid-12">
          <div className="customScroll">
            <CustomScroll
              heightRelativeToParent="calc(100%)"
              allowOuterScroll={true}
            >
              <div className="login-panel signin">
                <div className="login-panel-header">
                  <h1 className="login-title">Log In</h1>
                  <span data-labelledby="Facebook Log in" >
                    <FacebookLogin
                      appId={fbAppId}
                      fields="name,email,picture"
                      cssClass="btn btn-theme lg facebook blue"
                      textButton="Log In with Facebook"
                      callback={socialResponse => {
                        if (socialResponse && socialResponse.accessToken) {
                          this.props.actions.socialAuth(
                            socialResponse.accessToken,
                            "facebook",
                            "Facebook Sign in"
                          );
                        }
                      }}
                      isMobile={true}
                      disableMobileRedirect={true}
                      icon={<i className="mdi mdi-facebook" />}
                    />
                  </span>

                  <span data-labelledby="Google Log in" >
                    <GoogleLogin
                      clientId={googleClientId}
                      className="btn btn-theme lg google"
                      onSuccess={socialResponse => {
                        if (socialResponse && socialResponse.accessToken) {
                          this.props.actions.socialAuth(
                            socialResponse.accessToken,
                            "google",
                            "Google Sign in "
                          );
                        }
                      }}
                      onFailure={err => console.log('fail', err)} isSignedIn={true}
                    >
                      <i className="mdi mdi-google" />Log In with Google
                    </GoogleLogin>
                  </span>
                </div>
                <div className="or-text">
                  <span>OR</span>
                </div>
                <div className="login-panel-body">
                  {authReducer &&
                    authReducer.showErrorMessage && (
                      <AlertDismissable
                        bsStyle="danger"
                        closeLabel="Close alert"
                        closeAction={this.props.actions.hideErrorMessage}
                      >
                        <p>
                          {" "}
                          <i className="mdi mdi-block-helper" />{" "}
                          {authReducer.statusMessage}{" "}
                        </p>
                      </AlertDismissable>
                    )}
                  <TextInput
                    label="Email"
                    name="email"
                    type="email"
                    showValidationError={this.errors["email"]}
                    emptyError={validationMessage.email.emptyError}
                    validationError={validationMessage.email.validationError}
                    onBlur={this.onFieldBlur.bind(this)}
                  />
                  <TextInput
                    label="Password"
                    name="password"
                    type="password"
                    showValidationError={this.errors["password"]}
                    emptyError={validationMessage.password.emptyError}
                    validationError={validationMessage.password.validationError}
                    onBlur={this.onFieldBlur.bind(this)}
                    onKeyPress={this.onKeyPress.bind(this)}
                  />
                </div>
                <div className="login-panel-footer">
                  <a
                    onClick={e => {
                      e.preventDefault();
                      router.push(`${appBasePath}/forgot-password`);
                    }}
                    className="blue-text forgot-pswd"
                  >
                    Forgot Password ?
                  </a>
                  <Button
                    dataLabelledby="Log in"
                    btnCallBack={this.signInAction.bind(this)}
                    isSubmitBtn={true}
                    btnType="red"
                    btnSize="sm"
                    label="Log In"
                    isButtonLoading={this.state.isButtonLoading}
                  />
                </div>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    );
  }
}
