import * as types from "../actions/actionTypes";
import { assign } from "lodash";

const initialState = {
  signUpModalVisible: false,
  showErrorMessage: "",
  hideButtonLoader: true,
  loadedAccountStatus: false
};
export default function authenticationReducer(state = initialState, action) {

  console.log('What is auth actiontype ',action.type);

  switch (action.type) {
    case types.SHOW_SIGNUP_PAGE:
      return assign({}, state, {
        signUpModalVisible: false,
        currentComponentKey: "/sign-up"
      });
    case types.SHOW_SIGNIN_PAGE:
      return assign({}, state, {
        currentComponentKey: "/sign-in",
        isLoaded: true,
        showErrorMessage: false
      });
    case types.SHOW_VERIFY_OTP_PAGE:

      console.log('inside SHOW_VERIFY_OTP_PAGE ');

      return assign({}, state, {
        currentComponentKey: "/verify-otp",
        authData: action.authData,
        isLoaded: true,
        showErrorMessage: false,
        fromSignIn: action.fromSignIn
      });
    case types.SHOW_DASHBOARD:
      return assign({}, state, {
        currentComponentKey: state.redirectUrl
          ? state.redirectUrl
          : "/dashboard-plus",
        authData: action.authData,
        isLoaded: true,
        showErrorMessage: false,
        redirectUrl: ""
      });
    case types.VERIFY_OTP_PAGE:
      return assign({}, state, {
        authData: action.authData,
        currentComponentKey: "/verify-otp"
      });
    case types.VERIFY_OTP:
      return assign({}, state, { authData: action.authData });
    case types.SHOW_SEND_OTP_PAGE:
      return assign({}, state, {
        authData: action.authData,
        currentComponentKey: "/send-otp"
      });
    case types.SHOW_WELCOME_PAGE:
      console.log("SHOW_WELCOME_PAGE-----------")

      return assign({}, state, { currentComponentKey: "" });
    case types.SHOW_RESET_EMAIL_CONFIRMATION:
      return assign({}, state, {
        currentComponentKey: "/reset-email-confirmation",
        reset_email: action.email
      });
    case types.RESET_PASSWORD_CODE_VERIFIED:
      return assign({}, state, {
        currentComponentKey: "/confirmed",
        hideButtonLoader: true
      });
    case types.EMAIL_VERIFIED:
      return assign({}, state, { emailVerified: true });
    case types.SHOW_ERROR_MESSAGE:
      return assign({}, state, {
        currentComponentKey: "",
        showErrorMessage: true,
        statusMessage: action.statusMessage,
        isLoaded: false,
        hideButtonLoader: true
      });
    case types.HIDE_ERROR_MESSAGE:
      return assign({}, state, {
        currentComponentKey: "",
        showErrorMessage: false
      });
    case types.CLEAR_COMPONENT_KEY:
      return assign({}, state, { currentComponentKey: "" });
    case types.GET_ACCOUNT_STATUS: {
      return assign({}, state, {
        authData: action.authData,
        loadedAccountStatus: true
      });
    }
    case types.REDIRECT_URI: {
      return assign({}, state, { redirectUrl: action.payload });
    }
    default:
      return state;
  }
}
