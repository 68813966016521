import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import Gmaps from '../MyRequest/Gmaps';
import { forEach } from "lodash";
import CustomModal from '../common/CustomModal';
import { DropdownButton, MenuItem, InputGroup, FormControl, Modal, Media } from 'react-bootstrap';
import { map, filter, cloneDeep } from "lodash";
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import { imageBasePath } from '../../constants/staticData';

class Emergency extends Component {
    constructor(props, context) {
        super(props, context);
        this.savedLocation = [
            {
                address: "kr market",
                name: "home"
            },
            {
                address: "551, Mg Road",
                name: "work"
            }, {
                address: "mysore road"
            }
        ]
        this.toggleNotification = this.toggleNotification.bind(this);
        this.state = {
            searchMobile:false,
            locationDdStatus: false,
            searchedVal: "",
            locationModal: false,
            setCenter: false,
            mapsCenter: { lat: 12.9952672, lng: 77.5905857 },
            editClicked: false,
            filteredCarRepairCategories: undefined,
            visibleCategory: undefined,
            notificationVisible: false,
            messageVisible: false,
            currentPosition: {
                lat: 0,
                lng: 0,
                pinImage: `${imageBasePath}/images/map_blue_pointer.png`,
                dragEle: false

            },
            dragPosition: {
                lat: 13.1297797,
                lng: 78.1085657,
                pinImage: `${imageBasePath}/images/drag-loc.png`,
                dragEle: true
            },
        };

    }
    componentWillMount() {
        let modal = false;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition.bind(this));

        } else {
            alert("Geolocation is not supported by this browser.");
        }


    }

    showPosition(position) {
        let currentVal = cloneDeep(this.state.currentPosition);
        currentVal.lat = position.coords.latitude;
        currentVal.lng = position.coords.longitude;
        this.setState({ currentPosition: currentVal });
    }

    toggleNotification(isVisible) {
        this.setState({ 'notificationVisible': isVisible });
    }

    toggleMessage(isVisible) {
        this.setState({ 'messageVisible': isVisible });
    }
    markerDrag(map) {
        let position = {
            lat: map.latLng.lat(),
            lng: map.latLng.lng(),
            pinImage: `${imageBasePath}/images/drag-loc.png`,
            dragEle: true
        }
        this.setState({ dragPosition: position })
    }

    render() {
        const { commonReducer, actions, router } = this.props;
        let allMarkers = []
        allMarkers.push(this.state.currentPosition);
        allMarkers.push(this.state.dragPosition)
        const jobCardLocation = forEach(allMarkers, (loc) => {
            return {
                lat: loc.latitude, lng: loc.longitude, pinImage: loc.pinImage
            }
        });
        const filteredSavedLocation = filter(this.savedLocation, (location, key) => {
            if (this.state.searchedVal != "" && location.address.indexOf(this.state.searchedVal) != -1) {
                return location;
            } else if (this.state.searchedVal == "") {
                return location;
            }
        })

        return (
            <div className="jobUpdate">
                {/*Header*/}
                <Header hideOption={()=>this.setState({searchMobile:true})} commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
                <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
                <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
                <div className="main-wrapper">
                    {/*Sidebar*/}
                    <Sidebar router={this.props.router} />
                    {/*message*/}
                    {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
                    <div className="page-sec-header">
                        <div className="padwrapper">
                            <h4>Emergency</h4>
                        </div>
                    </div>
                    <div className="inSection emergency">
                        <div className="padwrapper">
                            <div className="maps-container mapSection">
                                <Gmaps
                                    setCenter={this.state.setCenter}
                                    center={this.state.mapsCenter}
                                    zoom={9}
                                    mapElement={<div className="map-align" style={{ height: 670 + 'px', width: `100%` }} />}
                                    containerElement={<div className="map-wrapper" style={{ height: 100 + '%', width: "1100px" }} />}
                                    markers={jobCardLocation}
                                    markerDrag={this.markerDrag.bind(this)}
                                />
                                <div className="emegency-card">
                                    <Media>
                                        <Media.Left>
                                            <img src={`${imageBasePath}/images/placeholder.png`} alt="Image" />
                                        </Media.Left>
                                        <Media.Body>
                                            <Media.Heading>
                                                Car Was Center Name
                                        <span className="mdi mdi-heart-outline" />
                                            </Media.Heading>
                                            <div className="rating rating-left">
                                                <span className={this.state.starSelected ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                                                <span className={this.state.starSelected ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                                                <span className={this.state.starSelected ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                                                <span className="mdi mdi-star-outline" />
                                                <span className="mdi mdi-star-outline" />
                                                <span className="rating-text"></span>
                                            </div>
                                            <span className="sub-name">
                                                Elite Motors
                                        <span className="distance">5km</span>
                                            </span>
                                        </Media.Body>
                                        <hr />
                                        <span className="ph-number">+971 919 233 470</span>
                                    </Media>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerSection" >
                    <Footer {...this.props}/>
                </div>
                <CustomModal hideModal={() => { this.setState({ locationModal: false }) }} id="locationModal" className="select-location-modal"
                    showModal={this.state.locationModal} footer={true} title="Select Location"
                    cancelText="Go Back" saveText="Confirm">
                    <Modal.Body>
                        <DropdownButton open={this.state.locationDdStatus} onToggle={(e) => this.setState({ locationDdStatus: e })} noCaret bsSize="large" title={
                            <InputGroup>
                                <FormControl type="text" placeholder="Seach Location" onChange={(e) => this.setState({ searchedVal: e.target.value, locationDdStatus: true })} />
                                {!this.state.locationDdStatus && <i className="mdi mdi-chevron-down" />}
                                {this.state.locationDdStatus && <i className="mdi mdi-chevron-up" />}
                                <InputGroup.Addon>
                                    <i className="mdi mdi-crosshairs-gps"></i>
                                </InputGroup.Addon>
                            </InputGroup>
                        } >
                            <MenuItem eventKey="current location" className="new-location">
                                <span className="title">
                                    <i className="mdi mdi-crosshairs-gps"></i>
                                    <span>Current Location</span>
                                </span>
                            </MenuItem>
                            {map(filteredSavedLocation, (location, key) => {
                                return (
                                    <MenuItem eventKey={location.name} className={location.name ? "" : "new-location"}>
                                        {location.name && <span className='title'>
                                            <i className="mdi mdi-home-variant"></i>
                                            <span>{location.name}</span>
                                        </span>}
                                        {!location.name && <i className="mdi mdi-map-marker"></i>}
                                        <span className={location.name ? "address" : "only-address"}>
                                            {location.address}
                                            {!location.name && <span className="save-edit">Save</span>}
                                            {location.name && <span className="save-edit">Edit</span>}
                                        </span>
                                    </MenuItem>
                                )
                            })}

                        </DropdownButton>
                    </Modal.Body>
                </CustomModal>
                {this.state.searchMobile && <div >

                    <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
                </div>}
            </div>
        );
    }
}

export default Emergency;
