import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import size  from 'lodash/size';
import cloneDeep  from 'lodash/cloneDeep';
import find  from 'lodash/find';
import findIndex  from 'lodash/findIndex';
import CustomModal from '../common/CustomModal';
import { Modal } from 'react-bootstrap';
import { queryStringtoArray } from '../../helpers/index';
import EditServiceSteps from './EditServiceSteps';
import ToastMessage from '../common/ToastMessage';
import SelectDropdown from '../common/SelectDropdown';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import { imageBasePath } from '../../constants/staticData';

export default class EditCarService extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      editClicked: false,
      checkedItemPresent: false,
      notificationVisible: false,
      messageVisible: false,
      carServiceCategories: [],
      filteredCarServiceCategories: [],
      checkedCategories: [],
      selectedCarCategoryForModel: undefined,
      selectedCarProfile: undefined,
      catDescriptionModalVisible: false,
      categoriesSelectedCount: 0,
      selectedCarServiceDataArray: [],
      vendorDetails: undefined,
      statusActive : false,
      vendorCarWashData : [],
      garageProfileDetails: '',
      subServiceIds: [],
      showError: false,
      filterKeyword:false,
      servicesFetched: undefined,
      selectedServiceId:null,
      selectedService:null,
      carInfo:{},
    };
  }

  componentWillMount() {
    //if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
   // }
    //if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    //}
    this.props.actions.clearImageArray();
    if (queryStringtoArray('requestId')) {
      this.props.actions.getBookingRequest(queryStringtoArray('requestId'), this.props.router);
    }
    
  }

  componentDidMount() {
   
    this.setState({ filteredCarServiceCategories: cloneDeep(this.state.carServiceCategories) })
  }

  componentWillReceiveProps(nextProps) {
    console.log('**********');
    console.log(nextProps);
    let bookingRequestDetails = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')];
    let selectedCarProfile = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vehicles;
    let carInfo = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vehicles;
    let serviceCategoryData = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData;
    let userbookingrequest_services = nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services.length && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services;
    let vendorId = nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vendorId;
    let statusActive = bookingRequestDetails && bookingRequestDetails.requeststatus && bookingRequestDetails.requeststatus.displayname === "Active" ? true : false;
    if (!this.state.vendorDetails && vendorId) {
      this.setState({
        ...this.state,
        vendorDetails: true
      });
      this.props.actions.getGarageProfileDetails(vendorId);
    }
    console.log('%%%%%%%%%')
    console.log(selectedCarProfile);
    this.setState({carInfo:carInfo});

    if (bookingRequestDetails && queryStringtoArray('requestId') && !this.state.selectedCarProfile && selectedCarProfile && queryStringtoArray('requestId') && serviceCategoryData && userbookingrequest_services && nextProps.bookingRequestReducer.servicesFetched) {
      let selectedCount = 0;
      let filteredCarServiceCategories = cloneDeep(nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.serviceCategoryData);
      userbookingrequest_services && userbookingrequest_services.map((bookedSerice) => {
        if (bookedSerice.serviceId) {
          if (!this.state.subServiceIds.includes(bookedSerice.serviceId) && filteredCarServiceCategories) {
            let count = 1;
            let findSubCategoryIndex;
            let findCategoryIndex = findIndex(filteredCarServiceCategories, { id: bookedSerice.parentId });
            if(findCategoryIndex > -1){
             findSubCategoryIndex = findIndex(filteredCarServiceCategories[findCategoryIndex]['sub_services'], { id: bookedSerice.serviceId });
            }
            if(findSubCategoryIndex > -1){
              filteredCarServiceCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = true;
              selectedCount = count + selectedCount;
              this.state.selectedCarServiceDataArray.push(filteredCarServiceCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
            }
            //this.changeCheckboxState({ target: { checked: true } }, bookedSerice.serviceId);
            this.state.subServiceIds.push(bookedSerice.serviceId);
          }
        }
      });
      let selectedService = userbookingrequest_services && userbookingrequest_services[0];
      console.log("selectedService",selectedService);
      if (bookingRequestDetails && userbookingrequest_services ) {
        this.setState({
          ...this.state,
          selectedCarServiceDataArray: this.state.selectedCarServiceDataArray,
          categoriesSelectedCount: selectedCount,
          filteredCarServiceCategories: filteredCarServiceCategories,
          carServiceCategories: filteredCarServiceCategories,
          selectedCarProfile: selectedCarProfile,
          bookingRequestDetails: bookingRequestDetails,
          statusActive : statusActive,
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          selectedService: userbookingrequest_services && userbookingrequest_services[0],
        });
      }

    }
    this.openCategory(bookingRequestDetails && bookingRequestDetails.userbookingrequest_services && bookingRequestDetails.userbookingrequest_services[0].parentId);
  }

  openCategory(id) {
    this.setState({
      'visibleCategory': id
    });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      selectedCarCategoryForModel: categoryDetails,
      catDescriptionModalVisible: true
    })
  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile
    });
  }

  select(carProfile) {
    this.setState({
      ...this.state,
      selectedServiceId: carProfile && carProfile.id ? carProfile.id : carProfile.serviceId ,
      selectedService: carProfile,
    });
  }

  renderCarType(data) {
    return (
      <div className="sub-collapse-panel ">
      <span className="service-card-holder n-service-holder">
        <figure >
          <img src={`${imageBasePath}"/images/carservice.png`} alt="" />
        </figure>
        <div className="sub-collapse-panel-head ">
          <h4 className="card-name">Car Service</h4>
          <i className="mdi mdi-information-outline"></i>
          <div className="dropdown">
            <SelectDropdown customPlaceholder="Enter Approx Mileage" selectedCarProfile={this.state.selectedService && this.state.selectedService} data={data} select={this.select.bind(this)}/>
          </div>
        </div>
      </span>
      </div>
    );
  }

  render() {
    const { commonReducer, actions, router, bookingRequestReducer } = this.props;

    let isCoverImage = this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        return (
          <div className="type-img car-img" key={key}>
            <img src={value.medium} alt="" />
          </div>
        );
      }
    });


    if(isCoverImage && this.state.selectedCarProfile && this.state.selectedCarProfile.images.length ){

      let coverImage = find(this.state.selectedCarProfile.images, (img) => { return img.isCover == true && img.type == "vehicle" });
      if (!coverImage) {

        isCoverImage = (
          <div className="type-img car-img">
            <img src={this.state.selectedCarProfile.images[0].medium} alt="" />
          </div>
        )
      }

    }

    return (
      <div className="jobUpdate">
        <Header hideOption={()=>this.setState({searchMobile:true})} commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          <Sidebar router={this.props.router} />
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Edit Car Service Request</h4>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                <div className="myCar-body col-md-8">
                  <EditServiceSteps
                    {...this.props}
                    carInfo={this.state.carInfo}
                    commonReducer={commonReducer}
                    bookedRequest={bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')]}
                    actions={actions}
                    bookingRequestReducer = {bookingRequestReducer}
                    servicesFetched = {this.state.servicesFetched}
                    showError = {this.props.bookingRequestReducer.toastType}
                    categoriesSelectedCount={this.state.categoriesSelectedCount}
                    router={this.props.router}
                    selectedServiceId={this.state.selectedServiceId}
                    selectedService={this.state.selectedService}
                    selectedCarProfile={this.state.selectedCarProfile}
                    carProfiles={this.props.carProfileReducer.carProfiles}
                    selectedCarServiceDataArray={this.state.selectedCarServiceDataArray}
                    viewBlock={this.renderCarType(this.state.filteredCarServiceCategories)}
                    editClicked={this.state.editClicked}
                    changeCarProfile={this.changeCarProfile.bind(this)}
                    bookingRequestDetails={this.state.bookingRequestDetails}
                    garageProfileDetails={this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                    bookingRequestId={queryStringtoArray('requestId')}
                  />
                </div>
                <div className="myCar-body col-md-4">
                  <div className="car-summary">
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      <div className="type-card card-display">
                        <div className="type-img">
                          <img className="cards" src={`${imageBasePath}/images/plus/service-card.svg`} alt="" />
                        </div>
                        <div className="type-desc service-type">
                          <h5>Service Type</h5>
                          <label>Car Service</label>
                        </div>
                      </div>
                    </div>
                    {this.state.selectedService && <div className="category-wrapper">
                      <div className="category-heading">
                        <h5>Categories Selected</h5>
                        {this.state.selectedService ?
                          <label>
                            <a onClick={() => this.setState({ editClicked: true })}>Edit</a>
                          </label>
                          :
                          []}
                      </div>
                      <div className="category-list">
                        <ul>
                          <li>
                            <label>{this.state.selectedService.name}</label>
                          </li>
                        </ul>
                      </div>
                    </div>}
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          <div className="type-img car-img">
                            {this.state.selectedCarProfile && this.state.selectedCarProfile.images.length ?
                              isCoverImage
                              :
                              <div className="type-img car-img">
                                <span className="car-profile-placeholder">
                                  <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                                </span>
                              </div>
                            }
                          </div>
                          <div className="type-desc">
                            <h5>Car Profile</h5>
                            <label>{this.state.selectedCarProfile.name}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    {!queryStringtoArray('vendor_id') ? <div className="car-wrapper">
                      <div className="type-card">
                        <div className="type-desc">
                          <h5>Service provider</h5>
                          <label className="label-text">Request will be sent to multiple service providers.</label>
                        </div>
                      </div>
                    </div> :
                      <div className="car-wrapper">
                        <div className="type-card">
                          {this.props.commonReducer.garageProfileDetails && this.props.commonReducer.garageProfileDetails.images && this.props.commonReducer.garageProfileDetails.images.length > 0 ?
                            <div className="type-img car-img">
                              <img src={this.props.commonReducer.garageProfileDetails.logo} alt="" />
                            </div>
                            :
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                              </span>
                            </div>
                          }
                          <div className="type-desc full-width">
                            <h5>Service provider</h5>
                            <label>{this.props.commonReducer.garageProfileDetails.companyprofilename}</label>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        <CustomModal showModal={this.state.catDescriptionModalVisible} title={this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.name} closeIcon={true} hideModal={() => { this.setState({ 'catDescriptionModalVisible': false }) }}>
          <Modal.Body>
            <p className="info-text">{this.state.selectedCarCategoryForModel && this.state.selectedCarCategoryForModel.description}</p>
          </Modal.Body>
        </CustomModal>
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
