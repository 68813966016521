import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import RequestCard from './RequestCard';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import FavouritesEmptyState from '../EmptyState/FavouritesEmptyState';
import PageLoader from '../common/PageLoader';
import MobileHeaderSearch from '../common/MobileHeaderSearch'

export default class Favourites extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      notificationVisible: false,
      messageVisible: false
    };
  }
  componentWillMount() {
    this.props.actions.getFavouriteVendors("distance");
  }
  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  render() {
    const { commonReducer, actions, router, bookingRequestReducer } = this.props;
    return (
      <div className="jobUpdate">
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})}  bookingRequestReducer={this.props.bookingRequestReducer}  commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          {/*Sidebar*/}
          <Sidebar router={this.props.router} />
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Favourites</h4>
            </div>
          </div>
           <div className="inSection">
            {bookingRequestReducer.favouriteVendorsLoaded ? bookingRequestReducer.favouriteVendorsList && bookingRequestReducer.favouriteVendorsList.length ? <div className="padwrapper">
              <div className="myCar-list request-wrapper">
                <div className="myCar-body row">
                  <RequestCard {...this.props}/>
                </div>
              </div>
            </div> :
          <FavouritesEmptyState router={router}/> : <PageLoader />}
          </div>
        </div>
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}

