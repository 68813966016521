import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import RepairSteps from './RepairSteps';

import each  from 'lodash/each';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import size  from 'lodash/size';
import cloneDeep  from 'lodash/cloneDeep';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';
import truncate  from 'lodash/truncate';


import SummaryCollpasePanel from '../common/SummaryCollpasePanel';
import { queryStringtoArray } from '../../helpers/index';
import CustomScroll from 'react-custom-scroll';
import CustomModal from '../common/CustomModal';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { imageBasePath } from '../../constants/staticData';

export default class CarRepair extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      infoModalData: undefined,
      selectedCarProfile: undefined,
      infoModal: true,
      editClicked: false,
      ChasisNumber: '',
      //filteredCarRepairCategories:undefined,
      visibleCategory: undefined,
      notificationVisible: false,
      messageVisible: false,
      carRepairCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.repairCategoryData ? cloneDeep(this.props.bookingRequestReducer.repairCategoryData) : [],
      filteredCarRepairCategories: this.props.bookingRequestReducer && this.props.bookingRequestReducer.repairCategoryData ? cloneDeep(this.props.bookingRequestReducer.repairCategoryData) : [],
      categoriesSelectedCount: 0,
      selectedCarRepairDataArray: [],
      promotionalOffersData: [],
      selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      showError: undefined,
      servicesFetched: props.bookingRequestReducer.servicesFetched ? true: false,
      filterKeyword: false,
      showChasis: true,
      offerFromCreateCarProfile: this.props.location  && this.props.location.state && this.props.location.state.offerFromCreateCarProfile ? this.props.location.state.offerFromCreateCarProfile : false,

    };
    this.changeCheckboxState = this.changeCheckboxState.bind(this);
  }

  componentWillMount() {
    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    } else {
      let selectedCarProfile = undefined;
      if (queryStringtoArray('car_id')) {
        map(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles, (carProfile) => {
          if (queryStringtoArray('car_id') == carProfile.id) {
            selectedCarProfile = carProfile;
          }
        });
        this.setState({
          ...this.state,
          selectedCarProfile: selectedCarProfile,
        });
      } else {
        this.setState({
          ...this.state,
          selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
        });
      }
    }
    if (queryStringtoArray('vendor_id')) {
      this.props.actions.getGarageProfileDetails(Number(queryStringtoArray('vendor_id')));
    }
    if (!(this.props.commonReducer.promotionalOffersData && this.props.commonReducer.promotionalOffersData.length)) {
      console.log('💔 💔 💔 0 ', this.props.commonReducer.promotionalOffersData);
      this.props.actions.getPromotionalOffers();
    }
  }

  componentWillReceiveProps(nextProps) {
    let getVendorsCarRepairData = [];
    let selectedCarProfile;
    //After Updation its getting reset to the first carprofile
    /*if (queryStringtoArray('car_id')) {
      map(nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles, (carProfile) => {
        if (queryStringtoArray('car_id') == carProfile.id) {
          selectedCarProfile = carProfile;
        }
      });
      this.setState({
        ...this.state,
        selectedCarProfile: selectedCarProfile,
      });
    } else {
      this.setState({
        ...this.state,
        selectedCarProfile: this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && this.props.carProfileReducer.carProfiles[Object.keys(this.props.carProfileReducer.carProfiles)[0]],
      });
    }*/
    if (!queryStringtoArray('vendor_id') && nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData && nextProps.bookingRequestReducer.servicesFetched && this.state.carRepairCategories && !this.state.carRepairCategories.length && this.state.filteredCarRepairCategories && !this.state.filteredCarRepairCategories.length) {
      this.setState({
        ...this.state,
        carRepairCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData,
        filteredCarRepairCategories: nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData,
        servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
      });
    }
    // else if (nextProps.bookingRequestReducer.err_userpreffereddatetime && !this.state.showError) {
    //   this.setState({
    //     ...this.state,
    //     showError: true,
    //   });
    //   // <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>
    //   alert(nextProps.bookingRequestReducer.err_userpreffereddatetime);
    // }
     else if (queryStringtoArray('vendor_id') && nextProps && nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services) {
        if(nextProps.commonReducer.garageProfileDetails.garage_services.length == 0) {
          this.setState({
            ...this.state,
            carRepairCategories: [],
            filteredCarRepairCategories: [],
            servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          });
        } else {
            nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData && nextProps.bookingRequestReducer.servicesFetched && nextProps.bookingRequestReducer.repairCategoryData.map((allSubService) => {
              let sub_services = [],services = {};
              allSubService.sub_services.map((subservices) => {
                nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services.map((vendorSubService) => {
                  if (subservices.id === vendorSubService.serviceId) {
                    sub_services.push(subservices);
                  }
                });
              });
              if(sub_services && sub_services.length > 0){
                services['description'] = allSubService.description;
                services['id'] = allSubService.id;
                services['image'] = allSubService.image;
                services['name'] = allSubService.name;
                services['parentId'] = allSubService.parentId;
                services['sub_services'] = sub_services;
              }
              console.log("nextProps 🔥",nextProps);
              if(size(services)){
                getVendorsCarRepairData.push(services);
              }
            });

          if (getVendorsCarRepairData.length) {
            this.setState({
              ...this.state,
              carRepairCategories: getVendorsCarRepairData,
              filteredCarRepairCategories: getVendorsCarRepairData,
              servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
            });
          } else {
            this.setState({
              ...this.state,
              carRepairCategories: [],
              filteredCarRepairCategories: [],
              servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
            });
          }
        }
    }
    if (queryStringtoArray('serviceId')) {
      this.openCategory(Number(queryStringtoArray('serviceId')));
    }


    if(! this.state.offerFromCreateCarProfile){
      this.setState({
        promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
        selectedPromotionalOffersData: this.props.location  && this.props.location.state && this.props.location.state.selectedPromotionalOffersData ? this.props.location.state.selectedPromotionalOffersData : null,
      });

    }

    let promoCategories = [];
    this.state.selectedPromotionalOffersData && each(this.state.selectedPromotionalOffersData.services, (promoCategory) => {
      promoCategories.push(promoCategory.serviceId);
      //  this.changeCheckboxState({ target: { checked: true } }, promoCategory.serviceId);

    });
    console.log('promoCategories --->',promoCategories);
    promoCategories && promoCategories.length && this.setState({
      selectedCarRepairDataArray: promoCategories,
      categoriesSelectedCount: promoCategories.length,

    });

  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      infoModal: true,
      infoModalData: categoryDetails
    });
  }

  hideModalCallback() {
    this.setState({ showChasis: false });
  }

  submitCallback(number) {
    console.log('The chasis Number is 💔', number);
    this.setState({
      ChasisNumber: number,
      showChasis: false
    });
  }
  disableEdit(){
    this.setState({
      ...this.state,
      editClicked: false,
    });
  }

  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.carRepairCategories);
      let filteredCarRepairCategories = {};
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          //filteredCarRepairCategories[carDetails.id] = carDetails;
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        } else {
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        }
      });
      this.setState({
        'filteredCarRepairCategories': filteredCarRepairCategories,
        filterKeyword: true
      });
    } else {
      this.setState({
        'filteredCarRepairCategories': cloneDeep(this.state.carRepairCategories),
        filterKeyword: false
      });
    }
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  openCategory(id) {
    if(this.state.visibleCategory == id){
      this.setState({
        'visibleCategory': undefined
      });
    }else{
      this.setState({
        'visibleCategory': id
      });
    }
  }

  renderCarType(carDetails, key,visibleCategory) {
    const checkedSubCategories = filter(carDetails.sub_services, (data) => { return (data.checked); });
    return (
      <div className="sub-collapse-panel" key={key}>
        <figure onClick={(e) => { this.showModal(e, carDetails); }}>
          <img src={carDetails.image} alt="" />
        </figure>
        <div className={carDetails.id == this.state.visibleCategory || carDetails.id == visibleCategory ? "sub-collapse-panel-head active" : "sub-collapse-panel-head "} onClick={(event) => { event.preventDefault(); this.openCategory(carDetails.id); }}>
          <h4>{carDetails.name}</h4>
          {
            carDetails.description && carDetails.description.length &&
            <span className="carDetails-info">
              <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Popover positionLeft={100} id={carDetails.name}> {carDetails.description.length < 50 ? carDetails.description : truncate(carDetails.description, { 'length': 80, 'separator': ' ' })}</Popover>}>
                <i className="mdi mdi-information-outline" />
              </OverlayTrigger>
            </span>
          }
          {checkedSubCategories && checkedSubCategories.length > 0 ?
            <span className="sub-category-count">
              {checkedSubCategories.length}{checkedSubCategories.length == 1 ? " Category Selected" : " Categories Selected"}
            </span>
            :
            <span className="sub-category-count">
              {carDetails && carDetails.sub_services && carDetails.sub_services.length} {carDetails && carDetails.sub_services && carDetails.sub_services.length == 1 ? "subcategory" : "subcategories"}
            </span>
          }
          <i className={carDetails.id == this.state.visibleCategory || carDetails.id == visibleCategory  ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
        </div>
        <div className={carDetails.id == this.state.visibleCategory || carDetails.id == visibleCategory  ? carDetails.sub_services.length > 10 ? "sub-collapse-panel-body sub-collapse-scroll" : "sub-collapse-panel-body" : "sub-collapse-panel-body hide"}>
          <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}>
            {map(carDetails.sub_services, (subCategory, index) => {
              return (<div className="options" key={index}>
                <span className="checkbox-style checkbox-wrapper">
                  <input type="checkbox" id={subCategory.id} checked={subCategory.checked ? subCategory.checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id, subCategory.id) }} value="" />
                  <label htmlFor={subCategory.id} className="custom-checkbox"></label>
                  <label htmlFor={subCategory.id}>
                    {subCategory.checked} {subCategory.name}
                  </label>
                </span>
              </div>);
            })}
          </CustomScroll>
        </div>
      </div>
    );
  }

  changeCheckboxState(e, categoryId, subCategoryId) {
    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const carRepairCategories = cloneDeep(this.state.carRepairCategories);
    const filteredCarRepairCategories = cloneDeep(this.state.filteredCarRepairCategories);
    if(this.state.filterKeyword) {
      filteredCarRepairCategories[categoryId]['sub_services'][subCategoryId]['checked'] = isChecked;
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }
      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: filteredCarRepairCategories
      });
    } else {
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }
      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: carRepairCategories
      });
    }
  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile
    });
  }

  render() {
    const { commonReducer, actions, router,bookingRequestReducer } = this.props;


    let isCoverImage = "";
    this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        isCoverImage =  (
          <div className="type-img car-img" key={key}>
            <img src={value.medium} alt="" />
          </div>
        );
      }
    });

    if(! isCoverImage && this.state.selectedCarProfile && this.state.selectedCarProfile.images.length){
      this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
        if (value.type == "vehicle") {
          isCoverImage =  (
            <div className="type-img car-img" key={key}>
              <img src={value.medium} alt="" />
            </div>
          );
        }
      });
    }


    let leftBlock = [];
    let catLength = 0;
    let selectedCarRepairDataArray = this.state.selectedCarRepairDataArray;
    let visibleCategory = "";
    each(this.state.filteredCarRepairCategories, (carDetails) => {




      each(carDetails.sub_services, function(subCarDetails, index){

        if(selectedCarRepairDataArray && selectedCarRepairDataArray.includes(subCarDetails.id)){


          carDetails.sub_services[index].checked = true;
          visibleCategory = carDetails.id;
        } else {
          carDetails.sub_services[index].checked = false;
        }
      });




      catLength += 1;
      
      leftBlock.push(this.renderCarType(carDetails, catLength,visibleCategory));
    });


    return (
      <div className="jobUpdate">
        <Header hideOption={()=>this.setState({searchMobile:true})} notificationCount={2} commonReducer={commonReducer} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          <Sidebar router={this.props.router} />
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Car Repair</h4>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                <div className="myCar-body col-md-8">
                  <RepairSteps
                    actions= {actions}
                    categoriesSelectedCount= {this.state.categoriesSelectedCount}
                    btnCallBack= {this.requestQuotes}
                    chasisNumber={this.state.ChasisNumber}
                    router= {this.props.router}
                    showError = {this.props.bookingRequestReducer.toastType}
                    servicesFetched = {this.state.servicesFetched}
                    selectedCarProfile= {this.state.selectedCarProfile}
                    carProfiles= {this.props.carProfileReducer.carProfiles}
                    commonReducer= {this.props.commonReducer}
                    bookingRequestReducer= {bookingRequestReducer}
                    selectedCarRepairDataArray= {this.state.selectedCarRepairDataArray}
                    promotionalOffersData = {this.state.promotionalOffersData}
                    selectedPromotionalOffersData = {this.state.selectedPromotionalOffersData}
                    viewBlock= {leftBlock}
                    searchView= {this.filterCarType.bind(this)}
                    disableEdit = {this.disableEdit.bind(this)}
                    editClicked= {this.state.editClicked}
                    changeCarProfile= {this.changeCarProfile.bind(this)}
                    garageProfileDetails= {queryStringtoArray('vendor_id') && this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                    carProfileReducer={this.props.carProfileReducer}
                  />
                </div>
                <div className="myCar-body col-md-4">
                  <div className="car-summary">
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      <div className="type-card card-display">
                        <div className="type-img">
                          <img className="cards" src={`${imageBasePath}/images/plus/repair-card.svg`} alt="" />
                        </div>
                        <div className="type-desc service-type">
                          <h5>Service Type</h5>
                          <label>Car Repair</label>
                        </div>
                      </div>
                    </div>
                    <div className="category-wrapper car-wrapper">
                      <div className="category-heading">
                        <h5>Categories Selected</h5>
                        {this.state.categoriesSelectedCount > 0 ?
                          <label>
                            <a onClick={() => this.setState({ editClicked: true })}>Edit</a>
                          </label>
                          :
                          []}
                      </div>
                      <div className="category-list">
                        {map(this.state.carRepairCategories, (subCat, key) => {
                          let count = 0;
                          let keyCopy = { key }
                          let checkedItem = false;
                          filter(subCat.sub_services, (checked) => {
                            if (checked.checked == true) {
                              checkedItem = true;
                              count += 1
                            }
                          });
                          if (checkedItem == true) {
                            return (
                              <SummaryCollpasePanel subCat={subCat} selectedChkBoxCount={count} />
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          <div className="type-img car-img">
                            {isCoverImage ?
                              isCoverImage
                              :
                              <div className="type-img car-img">
                                <span className="car-profile-placeholder">
                                  <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                                </span>
                              </div>
                            }
                          </div>
                          <div className="type-desc">
                            <h5>Car Profile</h5>
                            <label>{this.state.selectedCarProfile.name}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    {!queryStringtoArray('vendor_id') ? <div className="car-wrapper">
                      <div className="type-card">
                        <div className="type-desc full-width">
                          <h5>Service provider</h5>
                          <label className="label-text">Request will be sent to multiple service providers.</label>
                        </div>
                      </div>
                    </div> :
                      <div className="car-wrapper">
                        <div className="type-card">
                          {this.props.commonReducer.garageProfileDetails && this.props.commonReducer.garageProfileDetails.logo  ?
                              <div className="type-img car-img">
                                <img src={this.props.commonReducer.garageProfileDetails.logo} alt="" />
                              </div>
                            :
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                              </span>
                            </div>
                          }
                          <div className="type-desc full-width">
                            <h5>Service provider</h5>
                            <label>{this.props.commonReducer.garageProfileDetails.companyprofilename}</label>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.infoModalData && <CustomModal showModal={this.state.infoModal} title={this.state.infoModalData.name} closeIcon={true} hideModal={() => { this.setState({ infoModal: false }) }}>
          <Modal.Body>
            <p className="info-text">{this.state.infoModalData.description}</p>
          </Modal.Body>
        </CustomModal>}
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
        {/*<ChasisNumberPopup
          modalStatus={this.state.showChasis}
          hideModalCallback={this.hideModalCallback.bind(this)}
          submitCallback={this.submitCallback.bind(this)}
          actions= {actions}
          bookingRequestReducer={bookingRequestReducer}
        />*/}
      </div>
    );
  }
}
