import * as carcilityEnv from '../env-setup';
export var isProduction = carcilityEnv.env === 'prod';
export var isStaging = carcilityEnv.env === 'staging';
export const imageBasePath = "";
export const appBasePath = "";
export const googleClientId = isProduction
  ? "72058500133-8n0c0t2ev4vpfl4aieijqaifor6l5rf3.apps.googleusercontent.com"
 // : isStaging ? "72058500133-8n0c0t2ev4vpfl4aieijqaifor6l5rf3.apps.googleusercontent.com"
  : isStaging ? "805145423813-tv0khgomiape69eaei4ngrp8op618dfu.apps.googleusercontent.com"
    : "325191651728-3luk3tuh4h0in6svqoh0d74gkkdbb5f5.apps.googleusercontent.com";

export const gtmId = isProduction ? "GTM-MMR37VC" : isStaging ? "GTM-57WMLZ6" : "";
export const fbAppId = isProduction ? "2146640298685254" : isStaging? "1931777803704298": "2155602984765491";
export const reCaptchaSiteKey = isProduction ? "6LcJp1MUAAAAAO2f0EL5og0PUWD-NB9oA_Wv3vXo" : "6Lcbp1MUAAAAACakIPVnRQz3e77wNg3d6rqQEr__";
export const packageJson =  require('../../package.json');
export const canonicalLink = "https://www.carcility.com/";

export const baseLandingUrl = isProduction ? "https://carcility.com" : isStaging ? "https://stage.carcility.com" : "https://test.carcility.com";


export const defaultLat = "25.2048";
export const defaultLng = "55.2708";
export const defaultRadius = "100";
export const phoneNumber = "+971543040110";

export var emergencyIsComingSoon = true;
  if (localStorage.getItem('emergencyIsComingSoon')) {
     emergencyIsComingSoon = localStorage.getItem('emergencyIsComingSoon') == "true" ? true : false;
  }else{
    emergencyIsComingSoon = JSON.stringify(packageJson.emergencyIsComingSoon) == "true" ? true : false;
  }
export const serviceTypes = [
  {
    name: "Repair",
    className: "repair",
    serviceImage: imageBasePath + "/images/repair.png",
    hyperlink: "/car-repair",
    isComingSoon: false
  },
  {
    name: "Service",
    className: "servicing",
    serviceImage: imageBasePath + "/images/service.png",
    hyperlink: "/car-service",
    isComingSoon: false

  },
  {
    name: "Wash",
    className: "wash",
    serviceImage: imageBasePath + "/images/wash.png",
    hyperlink: "/car-wash",
    isComingSoon: false
  },
  {
    name: "Roadside Assistance",
    className: "emergency",
    serviceImage: imageBasePath + "/images/landing/car-roadside-assistance-service-in-dubai.jpg",
    hyperlink: "/emergency",
    isComingSoon: emergencyIsComingSoon
  }
];
export const plusServiceTypes = [
  {
    name: "Repair",
    className: "repair",
    serviceImage: imageBasePath + "/images/plus/repair-card.svg",
    hyperlink: "/car-repair",
    isComingSoon: false
  },
  {
    name: "Service",
    className: "servicing",
    serviceImage: imageBasePath + "/images/plus/service-card.svg",
    hyperlink: "/car-service",
    isComingSoon: false

  },
  {
    name: "Wash",
    className: "wash",
    serviceImage: imageBasePath + "/images/plus/wash-card.svg",
    hyperlink: "/car-wash",
    isComingSoon: false
  },
  {
    name: "Mobile service",
    className: "emergency",
    serviceImage: imageBasePath + "/images/plus/mobile-service-card.svg",
    hyperlink: "/emergency",
    isComingSoon: emergencyIsComingSoon
  }
];

export const validationMessage = {
  email: {
    emptyError: "Please enter your email id",
    validationError: "Enter a valid email id"
  },
  password: {
    emptyError: "Password cannot be empty",
    validationError: "Password should be greater than five digits"
  },
  name: {
    emptyError: "Name cannot be empty",
    validationError: "Enter your name"
  },
  phone: {
    emptyError: "Please enter your mobile number",
    validationError: "Enter a valid mobile number"
  },
  otp: {
    emptyError: "Incorrect OTP",
    validationError: "Please a valid OTP number"
  },
  captcha: {
    emptyError: "Incorrect Captcha",
    validationError: "Please validate captcha"
  },
  message: {
    emptyError: "Message cannot be empty",
    validationError: "Enter your message"
  },
  chassisNumber: {
    emptyError: "Chassis number cannot be empty",
    validationError: "Enter your chassis number"
  },
  platenumber: {
    emptyError: "Plate number cannot be empty",
    validationError: "Enter your Plate number"
  },
  car_details: {
    emptyError: "Car details cannot be empty",
    validationError: "Enter your car details"
  },
  category: {
    emptyError: "Category details cannot be empty",
    validationError: "Enter your category details"
  }
};

export const manufactures =[
  {icon:"chevrolet",name: "Chevrolet"},
  {icon:"nissan",name: "Nissan"},
  {icon:"toyota",name: "Toyota"},
  {icon:"kia",name: "Kia"},
  {icon:"mercedes-benz",name: "Mercedes Benz"},
  {icon:"mitsubishi",name: "Mitsubishi"},
  {icon:"ford",name: "Ford"},
  {icon:"hyundai",name: "Hyundai"},
  {icon:"suzuki",name: "Suzuki"},
  {icon:"honda",name: "Honda"},
  {icon:"gmc",name: "Gmc"},
  {icon:"alfa-romeo",name: "Alfa Romeo"},
  {icon:"bentley",name: "Bentley"},
  {icon:"cadillac",name: "Cadillac"},
  {icon:"chrysler",name: "Chrysler"},
  {icon:"dodge",name: "Dodge"},
  {icon:"fiat",name: "Fiat"},
  {icon:"infiniti",name:"Infiniti"},
  {icon:"jaguar",name:"Jaguar"},
  {icon:"jeep",name:"Jeep"},
  {icon:"land-rover",name:"Land Rover"},
  {icon:"lexus",name:"Lexus"},
  {icon:"mazda",name:"Mazda"},
  {icon:"mini",name:"Mini"},
  {icon:"peugeot",name:"Peugeot"},
  {icon:"renault",name:"Renault"},
  {icon:"volkswagen",name:"Volkswagen"},
  {icon:"volvo",name:"Volvo"},
  {icon:"aston-martin",name:"Aston Martin"},
  {icon:"byd",name:"Byd"},
  {icon:"citroen",name:"Citroen"},
  {icon:"chery",name:"Chery"},
  {icon:"ferrari",name:"Ferrari"},
  {icon:"geely",name:"Geely"},
  {icon:"great-wall",name:"Great Wall"},
  {icon:"isuzu",name:"Isuzu"},
  {icon:"lincoln",name:"Lincoln"},
  {icon:"lamborghini",name:"Lamborghini"},
  {icon:"lada",name:"Lada"},
  {icon:"daihatsu",name:"Daihatsu"},
  {icon:"lotus",name:"Lotus"},
  {icon:"maserati",name:"Maserati"},
  {icon:"mclaren",name:"Mclaren"},
  {icon:"mg",name:"Mg"},
  {icon:"opel",name:"Opel"},
  {icon:"porsche",name:"Porsche"},
  {icon:"proton",name:"Proton"},
  {icon:"seat",name:"Seat"},
  {icon:"skoda",name:"Skoda"},
  {icon:"ssang-yong",name:"Ssang Yong"},
  {icon:"subaru",name:"Subaru"},
  {icon:"tata",name:"Tata"},
  {icon:"luxgen",name:"Luxgen"},
  {icon:"jac",name:"Jac"},
  {icon:"foton",name:"Foton"},
  {icon:"audi", name: "Audi"},
  {icon:"baic",name:"Baic"},
  {icon:"gac",name:"Gac"},
  {icon:"kinglong",name:"KingLong"},
  {icon:"dfm",name:"Dfm"},
  {icon:"brilliance",name:"Brilliance"},
  {icon:"changan",name:"Changan"},
  {icon:"rolls-royce",name:"Rolls Royce"},
  {icon:"haval",name:"Haval"},
  {icon:"maxus",name:"Maxus"},
  {icon:"faw",name:"Faw"},
  {icon:"lifan",name:"Lifan"},
  {icon:"genesis",name:"Genesis"},
  {icon:"morgan",name:"Morgan"},
  {icon:"tesla",name:"Tesla"},
  {icon:"senova",name:"Senova"},
  {icon:"karry",name:"Karry"},
  {icon:"kenbo",name:"Kenbo"},
  {icon:"keyton",name:"Keyton"},
  {icon:"bugatti",name:"Bugatti"},
  {icon:"caterham",name:"Caterham"},
  {icon:"bmw", name: "Bmw"},
  {icon:"bear",name:"Bear"},
  {icon:"zarooq-motors",name:"Zarooq Motors"},
  {icon:"ram",name:"Ram"},
  {icon:"borgward",name:"Borgward"},
  {icon:"acura",name:"Acura"},
  {icon:"devel",name:"Devel"},
  {icon:"w-motors",name:"W Motors"},
  {icon:"mahindra",name:"Mahindra"},
  {icon:"abarth",name:"Abarth"},
  {icon:"zotye",name:"Zotye"},
  {icon:"dorcen",name:"Dorcen"},
  {icon:"sandstorm",name:"Sandstorm"},
  {icon:"soueast",name:"Soueast"},
  {icon:"jetour",name:"Jetour"},
];

export const metaTagData = {
  "contact-us": {
    title: "Carcility | Best Car Service Provider in Dubai | Book Now for Car Check Up.",
    headLink: "contact",
    description:
      "Get in touch with Carcility to book your car services in Dubai or get assistance about your car care experience."
  },
  landing: {
    title:
      "Best Car Service UAE - Car Repair & Maintenance Services | Carcility",
    headLink: "",
    description:
      "Book all your Car Service, Car Repair, Car Maintenance, Car Wash Services, in Dubai with top car service centers. Get the best car servicing with Carcility."
  },
  guestUser: {
    title: "Best Car Service Center in Dubai - Car Maintenance & Repair Services | Carcility",
    headLink: "",
    description: "All the car services in one-stop destination. Book your ✓Car Repair service ✓Car Maintenance ✓Car Wash Services ✓Roadside assistance in Dubai with Carcility."
  },
  faq: {
    title: "FAQs | Carcility",
    headLink: "blog/faq",
    description:
      "Get answers to all your questions about the best car care services in Dubai, car maintenance, car repair and more."
  },
  terms: {
    title: "Terms & Conditions | Carcility",
    headLink: "terms",
    description: "These Car Repair Terms and Conditions are designed for use by businesses and customers for providing repair services for a variety of vehicles.  This document is also suitable for the provision of routine and non-routine maintenance."
  },
  "sign-up": {
    title: "Join now and Book The Best Car Services Online | Carcility",
    headLink: "sign-up",
    description: "Sign Up with Carcility to get the best online auto service, Car Maintenance, Car Wash Service from verified car service centers in Dubai. Book your service now!"
  },
  "sign-in": {
    title: "Book Your Car Service Now | Carcility",
    headLink: "sign-in",
    description:
      "Sign in to your Carcility account to get the best car/auto services from verified car service centers in Dubai."
  },
  about: {
    title: "Car Service Dubai - Car Maintenance, Car Repair Services Online | Carcility",
    headLink: "about",
    description: "Carcility is a one-stop destination for all car/auto services in Dubai. Get the best car care services from expert mechanics on Carcility."
  },
  "guest-flow": {
    title: "Guest User | Carcility",
    headLink: "guest-flow",
    description:
      "Fill up the form to give details about your car service requirement. ✓ Create Car Profile ✓ Select Service ✓ Give Additional Details ✓ Sign Up"
  },
  register: {
    title: "Register as Customer or Business | Carcility",
    headLink: "register",
    description:
      "Sign Up page for Customers looking for Car Maintenance and Services providers in Dubai. Sign up today to  ✓ Find car repair service providers ✓ provide services to car owners ✓ Trusted professional Service"
  },
  "quickBooking": {
    title: "Book the Best Car Service - Fast and Fair Quotes | Carcility",
    description:"Select the car service you need and book for a car check-up. Get the car service center nearest to your location and get your car service without the hassle."
  },
  "quickBookingRepair": {
    title: "Get Your Car Repair At a Low Price In Dubai - Carcility",
    description:"Get all kinds of car repair, car ac repair done with our car service providers. Choose the best shop near you for your car."
  },
  "quickBookingWash": {
    title: "Book the Best Car Wash Service Center in Dubai - Carcility",
    description:"Book now to get your car wash anywhere in Dubai. Our car service providers specialized in all kinds of car wash like steam car wash, automatic car wash, mobile car wash. "
  },
  "quickBookingService": {
    title: "Get Your Car Service Done Fast - Book Now | Carcility",
    description:"Get all kinds of car service, car repair, car wash, car maintenance done from our expert car service providers. Book now & get the best price!"
  }
};
