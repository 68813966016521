import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import each  from 'lodash/each';
import map  from 'lodash/map';
import filter  from 'lodash/filter';
import size  from 'lodash/size';
import cloneDeep  from 'lodash/cloneDeep';
import find  from 'lodash/find';
import findIndex  from 'lodash/findIndex';
import remove  from 'lodash/remove';

import SummaryCollpasePanel from '../common/SummaryCollpasePanel';
import { queryStringtoArray } from '../../helpers/index';
import CustomScroll from 'react-custom-scroll';
import CustomModal from '../common/CustomModal';
import { Modal } from 'react-bootstrap';
import EditRepaiSteps from './EditRepairSteps';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import { imageBasePath } from '../../constants/staticData';

export default class EditCarRepair extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {

      searchMobile:false,
      infoModalData: undefined,
      selectedCarProfile: undefined,
      infoModal: false,
      editClicked: false,
      //filteredCarRepairCategories:undefined,
      visibleCategory: undefined,
      notificationVisible: false,
      messageVisible: false,
      carRepairCategories: [],
      filteredCarRepairCategories: [],
      categoriesSelectedCount: 0,
      selectedCarRepairDataArray: [],
      vendorDetails: undefined,
      statusActive : false,
      vendorCarWashData : [],
      garageProfileDetails: '',
      showError: false,
      subServiceIds: [],
      filterKeyword: false,
      servicesFetched: undefined,
      valuesSelected: false,
      carInfo:{},
    };
  }

  componentWillMount() {
    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    }
    if (queryStringtoArray('requestId')) {
      this.props.actions.getBookingRequest(queryStringtoArray('requestId'), this.props.router);
    }
  }

  componentDidMount() {
    this.setState({ filteredCarRepairCategories: cloneDeep(this.state.carRepairCategories) });
  }

  componentWillReceiveProps(nextProps) {
    let bookingRequestDetails = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')];
    let selectedCarProfile = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vehicles;
    let carInfo = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vehicles;
    let repairCategoryData = cloneDeep(nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData);
    let userbookingrequest_services = nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services.length && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services;
    let vendorId = nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vendorId;
    let statusActive = bookingRequestDetails && bookingRequestDetails.requeststatus && bookingRequestDetails.requeststatus.displayname === "Active" ? true : false;
    let filteredCarRepairCategories=[],getVendorsCarRepairData=[];

    this.setState({carInfo:carInfo});
    if (!this.state.vendorDetails && vendorId) {
      this.setState({
        ...this.state,
        vendorDetails: true
      });
      this.props.actions.getGarageProfileDetails(vendorId);
      filteredCarRepairCategories = cloneDeep(nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData);
    } else if(!vendorId) {
      filteredCarRepairCategories = cloneDeep(nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData);
    }

    // if(!this.state.filteredCarRepairCategories.length && vendorId && nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services){
    //   nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.repairCategoryData && nextProps.bookingRequestReducer.repairCategoryData.map((allSubService) => {
    //     let sub_services = [],services = {};
    //     allSubService.sub_services.map((subservices) => {
    //       nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services.map((vendorSubService) => {
    //         if (subservices.id === vendorSubService.serviceId) {
    //           sub_services.push(subservices);
    //         }
    //       });
    //     });
    //     if(sub_services && sub_services.length > 0){
    //       services['description'] = allSubService.description;
    //       services['id'] = allSubService.id;
    //       services['image'] = allSubService.image;
    //       services['name'] = allSubService.name;
    //       services['parentId'] = allSubService.parentId;
    //       services['sub_services'] = sub_services;
    //     }
    //     if(size(services)){
    //       getVendorsCarRepairData.push(services);
    //     }
    //   });
    //   filteredCarRepairCategories = getVendorsCarRepairData;
    // }

    if (filteredCarRepairCategories && bookingRequestDetails && queryStringtoArray('requestId') && !this.state.selectedCarProfile && selectedCarProfile && queryStringtoArray('requestId') && repairCategoryData && userbookingrequest_services && nextProps.bookingRequestReducer.servicesFetched) {
      let selectedCount = 0;
      userbookingrequest_services && userbookingrequest_services.map((bookedSerice) => {
        if (bookedSerice.serviceId) {
          if (!this.state.subServiceIds.includes(bookedSerice.serviceId)) {
            let count = 1;
            let findCategoryIndex = findIndex(filteredCarRepairCategories, { id: bookedSerice.parentId });
            let findSubCategoryIndex = findIndex(filteredCarRepairCategories[findCategoryIndex]['sub_services'], { id: bookedSerice.serviceId });
            filteredCarRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = true;
            selectedCount = count + selectedCount;
            this.state.selectedCarRepairDataArray.push(filteredCarRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
            //this.changeCheckboxState({ target: { checked: true } }, bookedSerice.serviceId);
            this.state.subServiceIds.push(bookedSerice.serviceId);
          }
        }
      });
      if (!this.state.valuesSelected && bookingRequestDetails && userbookingrequest_services && userbookingrequest_services.length == this.state.selectedCarRepairDataArray.length) {
        this.setState({
          ...this.state,
          selectedCarRepairDataArray: cloneDeep(this.state.selectedCarRepairDataArray),
          categoriesSelectedCount: selectedCount,
          valuesSelected: true,
          filteredCarRepairCategories: filteredCarRepairCategories,
          carRepairCategories: filteredCarRepairCategories,
          selectedCarProfile: selectedCarProfile,
          bookingRequestDetails: bookingRequestDetails,
          statusActive : statusActive,
          servicesFetched : nextProps.bookingRequestReducer.servicesFetched,
        });
      }
    }
    // else if (nextProps.bookingRequestReducer.err_userpreffereddatetime && !this.state.showError) {
    //   this.setState({
    //     ...this.state,
    //     showError: true,
    //   });
    //   alert(nextProps.bookingRequestReducer.err_userpreffereddatetime);
    // }
    this.openCategory(bookingRequestDetails && bookingRequestDetails.userbookingrequest_services && bookingRequestDetails.userbookingrequest_services[0].parentId);
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      infoModal: true,
      infoModalData: categoryDetails
    });
  }

  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.carRepairCategories);
      let filteredCarRepairCategories = {};
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          //filteredCarRepairCategories[carDetails.id] = carDetails;
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        } else {
          let modifiedCarDetails = cloneDeep(carDetails);
          let filteredSubCategory = {};
          each(carDetails.sub_services, (subCategory) => {
            const subCategoryName = subCategory.name.toLowerCase();
            if (subCategory.checked) {
              filteredSubCategory[subCategory.id] = subCategory;
            } else if (subCategoryName.indexOf(filterKeyword) > -1) {
              filteredSubCategory[subCategory.id] = subCategory;
            }
          });
          if (size(filteredSubCategory)) {
            modifiedCarDetails['sub_services'] = filteredSubCategory;
            filteredCarRepairCategories[carDetails.id] = modifiedCarDetails;
          }
        }
      });
      this.setState({
        'filteredCarRepairCategories': filteredCarRepairCategories,
        filterKeyword: true
      });
    } else {
      this.setState({
        'filteredCarRepairCategories': cloneDeep(this.state.carRepairCategories),
        filterKeyword: false
      });
    }
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  openCategory(id) {
    this.setState({
      'visibleCategory': id
    });
  }
  renderCarType(carDetails, key) {
    const checkedSubCategories = filter(carDetails.sub_services, (data) => { return (data.checked) });
    return (
      <div className="sub-collapse-panel" key={key}>
        <figure onClick={(e) => { this.showModal(e, carDetails); }}>
          <img src={carDetails.image} alt="" />
        </figure>
        <div className={carDetails.id == this.state.visibleCategory ? "sub-collapse-panel-head active" : "sub-collapse-panel-head "} onClick={(event) => {
           event.preventDefault();
           this.openCategory(carDetails.id);
          }}>
          <h4>{carDetails.name}</h4>
          {checkedSubCategories && checkedSubCategories.length > 0 ?
            <span className="sub-category-count">
              {checkedSubCategories.length}{checkedSubCategories.length == 1 ? " Category Selected" : " Categories Selected"}
            </span>
            :
            <span className="sub-category-count">
              {carDetails.sub_services.length} {carDetails.sub_services.length == 1 ? "subcategory" : "subcategories"}
            </span>
          }
          <i className={carDetails.id == this.state.visibleCategory ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
        </div>
        <div className={carDetails.id == this.state.visibleCategory ? carDetails.sub_services.length > 10 ? "sub-collapse-panel-body sub-collapse-scroll" : "sub-collapse-panel-body" : "sub-collapse-panel-body hide"}>
          <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={false}>
            {map(carDetails.sub_services, (subCategory, index) => {
              return (<div className="options" key={index}>
                <span className="checkbox-style checkbox-wrapper">
                  <input type="checkbox" id={subCategory.id} checked={subCategory.checked ? subCategory.checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id, subCategory.id) }} value="" />
                  <label htmlFor={subCategory.id} className="custom-checkbox"></label>
                  <label htmlFor={subCategory.id}>
                    {subCategory.checked} {subCategory.name}
                  </label>
                </span>
              </div>);
            })}
          </CustomScroll>
        </div>
      </div>
    );
  }

  changeCheckboxState(e, categoryId, subCategoryId) {
    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const carRepairCategories = cloneDeep(this.state.carRepairCategories);
    const filteredCarRepairCategories = cloneDeep(this.state.filteredCarRepairCategories);
    if(this.state.filterKeyword) {
      filteredCarRepairCategories[categoryId]['sub_services'][subCategoryId]['checked'] = isChecked;
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }
      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: filteredCarRepairCategories
      });
    } else {
      let findCategoryIndex = findIndex(carRepairCategories, { id: categoryId });
      let findSubCategoryIndex = findIndex(carRepairCategories[findCategoryIndex]['sub_services'], { id: subCategoryId });
      carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex]['checked'] = isChecked;
      if (isChecked) {
        count = count + this.state.categoriesSelectedCount;
        this.state.selectedCarRepairDataArray.push(carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id);
      } else {
        count = this.state.categoriesSelectedCount - count;
        removedCategoriesArray = remove(this.state.selectedCarRepairDataArray, (n) => {
          return n != carRepairCategories[findCategoryIndex]['sub_services'][findSubCategoryIndex].id;
        });
      }
      if (!removedCategoriesArray) {
        removedCategoriesArray = this.state.selectedCarRepairDataArray;
      }
      this.setState({
        selectedCarRepairDataArray: removedCategoriesArray,
        categoriesSelectedCount: count,
        carRepairCategories: carRepairCategories,
        filteredCarRepairCategories: carRepairCategories
      });
    }
  }

  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile
    });
  }

  render() {
    const { commonReducer, actions, router,bookingRequestReducer } = this.props;


    let isCoverImage = this.state.selectedCarProfile && this.state.selectedCarProfile.images.length && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover && value.type == "vehicle") {
        return (
          <div className="type-img car-img" key={key}>
            <img src={value.medium} alt="" />
          </div>
        );
      }
    });


    if(isCoverImage && this.state.selectedCarProfile && this.state.selectedCarProfile.images.length ){

      let coverImage = find(this.state.selectedCarProfile.images, (img) => { return img.isCover == true && img.type == "vehicle" });
      if (!coverImage) {

        isCoverImage = (
          <div className="type-img car-img">
            <img src={this.state.selectedCarProfile.images[0].medium} alt="" />
          </div>
        )
      }

    }



    let leftBlock = [];
    let catLength = 0;
    each(this.state.filteredCarRepairCategories, (carDetails) => {
      catLength += 1;
      leftBlock.push(this.renderCarType(carDetails, catLength));
    });
    return (
      <div className="jobUpdate">
        <Header hideOption={()=>this.setState({searchMobile:true})} notificationCount={2} commonReducer={commonReducer} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          <Sidebar router={this.props.router} />
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Edit Car Repair Request</h4>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                <div className="myCar-body col-md-8">
                  <EditRepaiSteps
                    carInfo={this.state.carInfo}
                    commonReducer={commonReducer}
                    bookedRequest={bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')]}
                    actions={actions}
                    bookingRequestReducer = {bookingRequestReducer}
                    categoriesSelectedCount={this.state.categoriesSelectedCount}
                    router={this.props.router}
                    showError = {this.props.bookingRequestReducer.toastType}
                    servicesFetched = {this.state.servicesFetched}
                    selectedCarProfile={this.state.selectedCarProfile}
                    carProfiles={this.props.carProfileReducer.carProfiles}
                    selectedCarRepairDataArray={this.state.selectedCarRepairDataArray}
                    viewBlock={leftBlock}
                    searchView={this.filterCarType.bind(this)}
                    editClicked={this.state.editClicked}
                    changeCarProfile={this.changeCarProfile.bind(this)}
                    bookingRequestDetails={this.state.bookingRequestDetails}
                    garageProfileDetails={this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                    bookingRequestId={queryStringtoArray('requestId')}
                  />
                </div>
                <div className="myCar-body col-md-4">
                  <div className="car-summary">
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      <div className="type-card card-display">
                        <div className="type-img">
                          <img className="cards" src={`${imageBasePath}/images/plus/repair-card.svg`} alt="" />
                        </div>
                        <div className="type-desc service-type">
                          <h5>Service Type</h5>
                          <label>Car Repair</label>
                        </div>
                      </div>
                    </div>
                    <div className="category-wrapper">
                      <div className="category-heading">
                        <h5>Categories Selected</h5>
                        {this.state.categoriesSelectedCount > 0 && !this.state.statusActive ?
                          <label>
                            <a onClick={() => this.setState({ editClicked: true })}>Edit</a>
                          </label>
                          :
                          []}
                      </div>
                      <div className="category-list">
                        {map(this.state.carRepairCategories, (subCat, key) => {
                          let count = 0;
                          let keyCopy = { key };
                          let checkedItem = false;
                          filter(subCat.sub_services, (checked) => {
                            if (checked.checked == true) {
                              checkedItem = true;
                              count += 1;
                            }
                          });
                          if (checkedItem == true) {
                            return (
                              <SummaryCollpasePanel subCat={subCat} selectedChkBoxCount={count} />
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          <div className="type-img car-img">
                            {this.state.selectedCarProfile.images.length ?
                              isCoverImage
                              :
                              <div className="type-img car-img">
                                <span className="car-profile-placeholder">
                                  <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                                </span>
                              </div>
                            }
                          </div>
                          <div className="type-desc">
                            <h5>Car Profile</h5>
                            <label>{this.state.selectedCarProfile.name}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    {!this.state.vendorDetails ? <div className="car-wrapper">
                      <div className="type-card">
                        <div className="type-desc full-width">
                          <h5>Service provider</h5>
                          <label className="label-text">Request will be sent to multiple service providers.</label>
                        </div>
                      </div>
                    </div> :
                      <div className="car-wrapper">
                        <div className="type-card">
                          {this.props.commonReducer.garageProfileDetails && this.props.commonReducer.garageProfileDetails.images && this.props.commonReducer.garageProfileDetails.images.length > 0 ?
                            <div className="type-img car-img">
                              <img src={this.props.commonReducer.garageProfileDetails.logo} alt="" />
                            </div>
                            :
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                              </span>
                            </div>
                          }
                          <div className="type-desc full-width">
                            <h5>Service provider</h5>
                            <label>{this.props.commonReducer.garageProfileDetails.companyprofilename}</label>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.infoModalData && <CustomModal showModal={this.state.infoModal} title={this.state.infoModalData.name} closeIcon={true} hideModal={() => { this.setState({ infoModal: false }) }}>
          <Modal.Body>
            <p className="info-text">{this.state.infoModalData.description}</p>
          </Modal.Body>
        </CustomModal>}
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
