import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import EditWashSteps from './EditWashSteps';
import each  from 'lodash/each';
import findIndex  from 'lodash/findIndex';
import cloneDeep  from 'lodash/cloneDeep';
import remove  from 'lodash/remove';
import size  from 'lodash/size';
import { queryStringtoArray } from '../../helpers/index';
import CustomModal from '../common/CustomModal';
import { Modal } from 'react-bootstrap';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import { imageBasePath } from '../../constants/staticData';

export default class EditCarWash extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile:false,
      infoModalData: undefined,
      infoModal: false,
      notificationVisible: false,
      editClicked: false,
      messageVisible: false,
      getCarWashData: [],
      searchGetCarWashData: [],
      categoriesSelectedCount: 0,
      selectedCarWashDataArray: [],
      selectedCarProfile: undefined,
      garageProfileDetails: '',
      subServiceIds: [],
      vendorDetails: undefined,
      statusActive : false,
      vendorCarWashData : [],
      servicesFetched: undefined,
      showError: undefined,
    };
  }

  componentWillMount() {
    this.props.actions.hideToastMessage();
    if (!(this.props.bookingRequestReducer && this.props.bookingRequestReducer.getAllServices && this.props.bookingRequestReducer.getAllServices.length)) {
      this.props.actions.getAllServices();
    }
    if (!(this.props.carProfileReducer && this.props.carProfileReducer.carProfiles && size(this.props.carProfileReducer.carProfiles) > 0)) {
      this.props.actions.getCarProfileList(this.props.router);
    }
    if (queryStringtoArray('requestId')) {
      this.props.actions.getBookingRequest(queryStringtoArray('requestId'), this.props.router);
    }
  }

  componentWillReceiveProps(nextProps) {
    let bookingRequestDetails = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')];
    let selectedCarProfile = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vehicles;
    let washCategoryData = nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData;
    let userbookingrequest_services = nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services.length && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].userbookingrequest_services;
    let vendorId = nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.bookingRequests && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')] && nextProps.bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')].vendorId;
    let statusActive = bookingRequestDetails && bookingRequestDetails.requeststatus && bookingRequestDetails.requeststatus.displayname === "Active" ? true : false
    if (!this.state.vendorDetails && vendorId) {
      this.setState({
        ...this.state,
        vendorDetails: true
      });
      this.props.actions.getGarageProfileDetails(vendorId);
    }
    if (!vendorId && bookingRequestDetails && queryStringtoArray('requestId') && !this.state.selectedCarProfile && selectedCarProfile && queryStringtoArray('requestId') && washCategoryData && userbookingrequest_services && nextProps.bookingRequestReducer.servicesFetched) {
      let selectedCount = 0;
      let filteredCarWashCategories = cloneDeep(nextProps && nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.washCategoryData);
      userbookingrequest_services && userbookingrequest_services.map((bookedSerice) => {
        if (bookedSerice.serviceId) {
          if (!this.state.subServiceIds.includes(bookedSerice.serviceId)) {
            let count = 1;
            let findCategoryIndex = findIndex(filteredCarWashCategories, { id: bookedSerice.serviceId });
            filteredCarWashCategories[findCategoryIndex]['checked'] = true;
            selectedCount = count + selectedCount;
            this.state.selectedCarWashDataArray.push(filteredCarWashCategories[findCategoryIndex].id);
            //this.changeCheckboxState({ target: { checked: true } }, bookedSerice.serviceId);
            this.state.subServiceIds.push(bookedSerice.serviceId);
          }
        }
      });
      if (bookingRequestDetails && userbookingrequest_services && userbookingrequest_services.length == this.state.selectedCarWashDataArray.length) {
        this.setState({
          ...this.state,
          selectedCarWashDataArray: this.state.selectedCarWashDataArray,
          categoriesSelectedCount: selectedCount,
          searchGetCarWashData: filteredCarWashCategories,
          getCarWashData: filteredCarWashCategories,
          selectedCarProfile: selectedCarProfile,
          bookingRequestDetails: bookingRequestDetails,
          statusActive : statusActive,
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
        });
      }
    } else if (vendorId && bookingRequestDetails && queryStringtoArray('requestId') && !this.state.selectedCarProfile && selectedCarProfile && queryStringtoArray('requestId') && washCategoryData && userbookingrequest_services && nextProps.bookingRequestReducer.servicesFetched) {
      let selectedCount = 0;
      nextProps.commonReducer && nextProps.commonReducer.garageProfileDetails && nextProps.commonReducer.garageProfileDetails.garage_services &&  nextProps.commonReducer.garageProfileDetails.garage_services.map((vendorSubService) => {
        userbookingrequest_services && userbookingrequest_services.map((bookedSerice) => {
          let filteredCarWashCategories = cloneDeep(vendorSubService);
          if (vendorSubService.serviceId === bookedSerice.serviceId) {
            if (!this.state.subServiceIds.includes(bookedSerice.serviceId)) {
              let count = 1;
              filteredCarWashCategories['checked'] = true;
              selectedCount = count + selectedCount;
              this.state.vendorCarWashData.push(filteredCarWashCategories);
              this.state.selectedCarWashDataArray.push(filteredCarWashCategories.serviceId);
              //this.changeCheckboxState({ target: { checked: true } }, bookedSerice.serviceId);
              this.state.subServiceIds.push(vendorSubService);
            }
          }else {
            washCategoryData.map((washCategoryData)=>{
              if(washCategoryData.id == vendorSubService.serviceId){
                this.state.vendorCarWashData.push(washCategoryData);
              }
            });
          }
        });
      });
      if (userbookingrequest_services && userbookingrequest_services.length == this.state.selectedCarWashDataArray.length) {
        this.setState({
          ...this.state,
          selectedCarWashDataArray: this.state.selectedCarWashDataArray,
          categoriesSelectedCount: selectedCount,
          searchGetCarWashData: this.state.vendorCarWashData,
          servicesFetched: nextProps.bookingRequestReducer.servicesFetched,
          getCarWashData: this.state.vendorCarWashData,
          selectedCarProfile: selectedCarProfile,
          bookingRequestDetails: bookingRequestDetails,
          statusActive : statusActive
        });
      }
    }
    // else if (nextProps.bookingRequestReducer.err_userpreffereddatetime && !this.state.showError) {
    //   //<ToastMessage show={this.state.showToast}/>
    //   alert(nextProps.bookingRequestReducer.err_userpreffereddatetime);
    //   this.setState({
    //     ...this.state,
    //     showError: true,
    //   });
    // }
  }

  showModal(e, categoryDetails) {
    e.preventDefault();
    this.setState({
      infoModal: true,
      infoModalData: categoryDetails
    });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  renderCarType(carDetails, key) {
    if (carDetails) {
      return (
        <div className="sub-collapse-panel wash-collapse" key={key}>
          <span className="checkbox-style checkbox-wrapper">
            <label htmlFor={carDetails.id}>
              <figure onClick={(e) => { this.showModal(e, carDetails); }}>
                <img src={carDetails.image ? carDetails.image : `${imageBasePath}/images/placeholder.png`} alt="" />
              </figure>
              <div className={carDetails.id == this.state.visibleCategory ? "sub-collapse-myCar-body col-md-8 active" : "sub-collapse-panel-head "} >
                <h4>{carDetails.name}</h4>
                <div className="select-type">
                  <input type="checkbox" id={carDetails.id} checked={carDetails.checked ? carDetails.checked : false} onChange={(e) => { this.changeCheckboxState(e, carDetails.id); }} value="" />
                  <label htmlFor={carDetails.id} className="custom-checkbox"></label>
                </div>
              </div>
            </label>
          </span>
        </div>
      );
    }
  }


  changeCarProfile(carProfile) {
    this.setState({
      ...this.state,
      selectedCarProfile: carProfile
    });
  }

  changeCheckboxState(e, categoryId) {
    let count = 1;
    let removedCategoriesArray;
    const isChecked = e.target.checked;
    const filteredCarWashCategories = cloneDeep(this.state.getCarWashData);
    let findCategoryIndex = findIndex(filteredCarWashCategories, { id: categoryId });
    filteredCarWashCategories[findCategoryIndex]['checked'] = isChecked;
    if (isChecked) {
      count = count + this.state.categoriesSelectedCount;
      this.state.selectedCarWashDataArray.push(filteredCarWashCategories[findCategoryIndex].id);
    } else {
      count = this.state.categoriesSelectedCount - count;
      removedCategoriesArray = remove(this.state.selectedCarWashDataArray, (n) => {
        return n != filteredCarWashCategories[findCategoryIndex].id;
      });
    }
    if (!removedCategoriesArray) {
      removedCategoriesArray = this.state.selectedCarWashDataArray;
    }
    this.setState({
      ...this.state,
      selectedCarWashDataArray: removedCategoriesArray,
      categoriesSelectedCount: count,
      searchGetCarWashData: filteredCarWashCategories,
      getCarWashData: filteredCarWashCategories
    });
  }

  //******* Search Categories **********************************************************************************************************************************/
  filterCarType(searchTerm) {
    const filterKeyword = searchTerm.toLowerCase();
    if (filterKeyword) {
      const carCategories = cloneDeep(this.state.getCarWashData);
      let filteredCarWashCategories = [];
      each(carCategories, (carDetails) => {
        const carName = carDetails.name.toLowerCase();
        if (carName.indexOf(filterKeyword) > -1) {
          filteredCarWashCategories.push(carDetails);
        }
      });
      this.setState({
        ...this.state,
        searchGetCarWashData: filteredCarWashCategories,
      });
    } else {
      this.setState({
        ...this.state,
        searchGetCarWashData: cloneDeep(this.state.getCarWashData)
      });
    }
  }
  //********************************************************************************************************************************************************** */
  render() {
    const { commonReducer, actions, router, bookingRequestReducer } = this.props;
    const isCoverImage = this.state.selectedCarProfile && this.state.selectedCarProfile.images && this.state.selectedCarProfile.images.length > 0 && this.state.selectedCarProfile.images.map((value, key) => {
      if (value.isCover) {
        return (
          <div className="type-img car-img" key={key}>
            < img src={value.medium} alt="" />
          </div>
        );
      }
    });

    let leftBlock = [];
    let catLength = 0;
    {
      this.state.searchGetCarWashData && this.state.searchGetCarWashData.length && each(this.state.searchGetCarWashData, (carDetails) => {
        catLength += 1;
        leftBlock.push(this.renderCarType(carDetails, catLength));
      });
    }

    const selectedCarWashData = this.state.getCarWashData && this.state.getCarWashData.map((checked, key) => {
      if (checked.checked == true) {
        return (
          <li key={key}>
            <label>{checked.name}</label>
          </li>
        );
      }
    });

    return (
      <div className="jobUpdate">
        <Header  hideOption={()=>this.setState({searchMobile:true})} notificationCount={2} commonReducer={commonReducer} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper">
          <Sidebar router={this.props.router} />
          <div className="page-sec-header">
            <div className="padwrapper">
              <h4>Edit Car Wash Request</h4>
            </div>
          </div>
          <div className="inSection">
            <div className="padwrapper padwrapper-align">
              <div className="myCar-list row">
                {this.state.getCarWashData && <div className="myCar-body col-md-8">
                  <EditWashSteps
                    commonReducer={commonReducer}
                    bookedRequest={bookingRequestReducer.bookingRequests[queryStringtoArray('requestId')]}
                    actions={actions}
                    servicesFetched = {this.state.servicesFetched}
                    categoriesSelectedCount={this.state.categoriesSelectedCount}
                    router={this.props.router}
                    selectedCarProfile={this.state.selectedCarProfile}
                    carProfiles={this.props.carProfileReducer.carProfiles}
                    selectedCarWashDataArray={this.state.selectedCarWashDataArray}
                    viewBlock={leftBlock}
                    showError = {this.props.bookingRequestReducer.toastType}
                    searchView={this.filterCarType.bind(this)}
                    editClicked={this.state.editClicked}
                    changeCarProfile={this.changeCarProfile.bind(this)}
                    bookingRequestDetails={this.state.bookingRequestDetails}
                    garageProfileDetails={this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails}
                    bookingRequestId={queryStringtoArray('requestId')}
                  />
                </div>}
                <div className="myCar-body col-md-4">
                  <div className="car-summary">
                    <div className="summary-heading">
                      Summary
                    </div>
                    <div className="car-wrapper">
                      <div className="type-card card-display">
                        <div className="type-img">
                          <img className="cards" src={`${imageBasePath}/images/plus/wash-card.svg`} alt="" />
                        </div>
                        <div className="type-desc service-type">
                          <h5>Service Type</h5>
                          <label>Car Wash</label>
                        </div>
                      </div>
                    </div>
                    <div className="category-wrapper">
                      <div className="category-heading">
                        <h5>Categories Selected</h5>
                        {this.state.categoriesSelectedCount > 0 && !this.state.statusActive ?
                          <label>
                            <a onClick={() => this.setState({ editClicked: true })}>Edit</a>
                          </label>
                          :
                          []}
                      </div>
                      <div className="category-list">
                        <ul>
                          {selectedCarWashData}
                        </ul>
                      </div>
                    </div>
                    <div className="car-wrapper">
                      {this.state.selectedCarProfile ?
                        <div className="type-card">
                          {this.state.selectedCarProfile.images.length ?
                            isCoverImage
                            :
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                              </span>
                            </div>
                          }
                          <div className="type-desc">
                            <h5>Car Profile</h5>
                            <label>{this.state.selectedCarProfile.name}</label>
                          </div>
                        </div> :
                        <div className="type-card">
                          <div className="type-desc only-desc">
                            <h5>Car Profile</h5>
                            <label>Car Profile yet to be selected</label>
                          </div>
                        </div>}
                    </div>
                    {!this.state.vendorDetails ? <div className="car-wrapper">
                      <div className="type-card">
                        <div className="type-desc full-width">
                          <h5>Service provider</h5>
                          <label className="label-text">Request will be sent to multiple service providers.</label>
                        </div>
                      </div>
                    </div> :
                      <div className="car-wrapper">
                        <div className="type-card">
                          {this.props && this.props.commonReducer && this.props.commonReducer.garageProfileDetails && this.props.commonReducer.garageProfileDetails.images && this.props.commonReducer.garageProfileDetails.images.length > 0 && this.props.commonReducer.garageProfileDetails.images ?
                            <div className="type-img car-img">
                              <img src={this.props.commonReducer.garageProfileDetails.logo} alt="" />
                            </div> :
                            <div className="type-img car-img">
                              <span className="car-profile-placeholder">
                                <img src={`${imageBasePath}/images/placeholder.png`} alt="Carcility" />
                              </span>
                            </div>
                          }
                          <div className="type-desc full-width">
                            <h5>Service provider</h5>
                            <label>{this.props.commonReducer.garageProfileDetails.companyprofilename}</label>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage}/>}
        <div className="footerSection" >
          <Footer {...this.props}/>
        </div>
        {this.state.infoModalData && <CustomModal showModal={this.state.infoModal} title={this.state.infoModalData.name} closeIcon={true} hideModal={() => { this.setState({ infoModal: false }) }}>
          <Modal.Body>
            <p className="info-text">{this.state.infoModalData.description}</p>
          </Modal.Body>
        </CustomModal>}
        {this.state.searchMobile && <div >

            <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}
      </div>
    );
  }
}
