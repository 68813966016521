import React, { Component } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import Header from './common/Header';
import Sidebar from './common/Sidebar';
import Deals from './common/Deals';
import Footer from './common/Footer';
import JobUpdate from './common/JobUpdate';
import WelcomeText from './common/WelcomeText';
import EmptyUpdates from './common/EmptyUpdates';
import MobileNotification from './common/MobileNotification';
import MobileMessage from './common/MobileMessage';
import CreateRequestPopup from './common/CreateRequestPopup';
import { serviceTypes, appBasePath } from '../constants/staticData';
import PageLoader from './common/PageLoader';
import { Steps } from 'intro.js-react';
import MobileHeaderSearch from './common/MobileHeaderSearch'
import queryString from 'query-string'
import TagManager from "react-gtm-module";
import Offers from "./common/Offers";
import Workshops from "./common/Workshops";
import MobilText from "./common/MobilText";
import FloatingMenu from "../components/LandingNew/FloatingMenu";

export default class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {
      searchMobile: false,
      isLoading: false,
      notificationVisible: false,
      messageVisible: false,
      searchVisible: false,
      bookServiceModalVisible: false,
      stepsEnabled: false,
      isOfferLoading: true,
      isWorkshopsLoading: true,
      initialStep: 0,
      steps: [
        {
          element: '.notification-menu.desktop-view',
          intro: 'Get instant notifications of all your activities.',
        },
        {
          element: '.header-search',
          intro: 'Get a list of all business nearby with a simple search',
        },
        {
          element: '.inSection.dash-inSec .padwrapper',
          intro: 'Select from a wide range of categories for all your car needs',
        },
      ],
      promotionalOffersData: [],
      workshopsData: [],
    };
  }

  componentWillMount() {
    console.log('I am HITTER ');
    this.setState({ isLoading: true });
    this.props.actions.getAllBookingRequests('all', 5, 0);
    this.props.actions.clearComponentKey();

    //if (!(this.props.commonReducer.promotionalOffersData && this.props.commonReducer.promotionalOffersData.length)) {
    this.props.actions.getPromotionalOffers();
    this.props.actions.getWorkshops();
    this.props.actions.getUserPaymentStatus();
    //}
  }
  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }
  onExit() {
    this.setState(() => ({ stepsEnabled: false }));
  }
  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }
  toggleSearch(isVisible) {
    this.setState({ 'searchVisible': isVisible });
  }
  componentWillReceiveProps(nextProps) {
    let authData = localStorage.getItem('authData');
    authData = authData ? JSON.parse(authData) : '';
    const walkthroughCompleted = localStorage.getItem('walkthroughCompleted');
    if (nextProps.bookingRequestReducer.isLoaded) {
      this.setState({ isLoading: false });
      if ((authData && authData.isFirstTimeLoggedin) && !this.state.stepsEnabled && !walkthroughCompleted) {
        localStorage.setItem('walkthroughCompleted', true);
        setTimeout(() => {
          this.setState({ stepsEnabled: true });
        },
          1000);
      }
    }
    this.setState({
      promotionalOffersData: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? nextProps.commonReducer.promotionalOffersData : [],
      isOfferLoading: nextProps.commonReducer && nextProps.commonReducer.promotionalOffersData ? false : true,
      workshopsData: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? nextProps.commonReducer.vendorsListFromSearch : [],
      isWorkshopsLoading: nextProps.commonReducer && nextProps.commonReducer.vendorsListFromSearch ? false : true,
      userPaymentStatus: this.props.commonReducer && this.props.commonReducer.userPaymentStatusData && this.props.commonReducer.userPaymentStatusData.enabled,

    });


  }


  render() {

    let pageCategory = "";
    const urlData = queryString.parse(this.props.location.search);
    if (urlData && urlData.route) {

      if (urlData.route == "guestuser") {
        pageCategory = 'Guest User Sign Up';
      } else if (urlData.route == "signup") {
        pageCategory = 'Normal Sign Up';
      } else if (urlData.route == "signin") {
        pageCategory = 'Normal Sign In';
      }

    }
    const tagManagerArgs = {
      dataLayer: {
        pageCategory: pageCategory,
      },
      dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs);


    const { bookingRequestReducer } = this.props;
    let bookingRequests = (bookingRequestReducer.dashboardJobStatus && size(bookingRequestReducer.dashboardJobStatus)) ? bookingRequestReducer.dashboardJobStatus : undefined;

    let allBookingRequests = this.state.promotionalOffersData.length > 0 && bookingRequests ? [bookingRequests[0]] : bookingRequests;

    const { commonReducer, router, actions, authReducer } = this.props;
    const authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : '';
    const hasVehicleProfile = authData && authData.hasVehicleProfile ? true : false;
    const serviceTypesView = map(serviceTypes, (service, key) => {
      return (
        <div className="col-md-3 col-sm-3 col-xs-6 mpad-0" key={key} onClick={() => { service.isComingSoon ? true : (hasVehicleProfile ? router.push(appBasePath + service.hyperlink) : router.push(`${appBasePath}/car-profiles/create?redirect_to=${service.hyperlink.substr(1, service.hyperlink.length)}`)) }}>
          <div className="service-data">
            <img src={service.serviceImage} alt="" />
            <label>{service.name}</label>
          </div>
        </div>
      );
    });

    return (
      <div>
      <FloatingMenu  />
        <Steps
          enabled={this.state.stepsEnabled}
          steps={this.state.steps}
          initialStep={this.state.initialStep}
          onExit={this.onExit.bind(this)}
          options={
            {
              highlightClass: "custom-highlight",
              showStepNumbers: false,
              showProgress: false,
              doneLabel: "Done",
              skipLabel: '',
              tooltipClass: "dashbaord-intro",
              showBullets: false,
              nextLabel: 'Next',
              prevLabel: "Previous"
            }
          }

        />

        {/*Header*/}
        <Header
          hideOption={() => this.setState({ searchMobile: true })}
          bookingRequestReducer={this.props.bookingRequestReducer}
          customClass={this.state.stepsEnabled ? "walkthrough" : ""}
          commonReducer={commonReducer}
          notificationCount={2}
          profileName="Derrick Frank"
          notificationCallBack={this.toggleNotification}
          messageCallBack={this.toggleMessage.bind(this)}
          searchCallBack={this.toggleSearch.bind(this)}
          router={router}
          actions={actions}
          authReducer={this.props.authReducer}
        />
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />

        <div>
          <div className="main-wrapper dashboard-wrapper ">
            {/*Sidebar*/}
            <Sidebar router={router} />
            {/*message*/}
            {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
            {this.state.promotionalOffersData && this.state.promotionalOffersData.length > 0 && <div className="topSection">
              <div className="padwrapper">
                {!this.state.promotionalOffersData[0].appOnly &&
                  <WelcomeText router={router} authData={authData}
                    promotionalOffersData={this.state.promotionalOffersData} />
                }

              </div>
            </div>}

            <div className=" dash-jobupdate-bg dash-inSec">
              <div className="padwrapper">
                {/*Job Updates*/}
                <div className="jobUpdate-padding">
                  <MobilText />
                </div>
              </div>
            </div>

            <div className=" dash-inSec">
              <div className="padwrapper">
                {/*Service List*/}
                <h4 className="serviceList-title">Pick a Service, to get a quote</h4>
                <div className="service-list text-center row">
                  {serviceTypesView}
                </div>
              </div>
            </div>

            {!this.state.isLoading ? (allBookingRequests && allBookingRequests.length > 0) ?
              <div className=" dash-jobupdate-bg dash-inSec">
                <div className="padwrapper">
                  {/*Job Updates*/}
                  <div className="jobUpdate-padding">
                    <JobUpdate userPaymentStatus={this.state.userPaymentStatus} authReducer={this.props.authReducer} actions={this.props.actions} bookingRequestReducer={this.props.bookingRequestReducer} router={router} allOpenRequests={allBookingRequests} />
                  </div>
                </div>
              </div>
              :
              <div className="padwrapper">

                  {/*Empty Text*/}
                  <EmptyUpdates router={router} btnCallBack={() => { this.setState({ 'bookServiceModalVisible': true }) }} />
              </div> : <PageLoader />
            }

            <div className="padwrapper">
              {!this.state.isOfferLoading ? this.state.promotionalOffersData && this.state.promotionalOffersData.length > 0 &&
                <Offers
                  title="Offers"
                  viewAll={true}
                  promotionalOffersData={this.state.promotionalOffersData}
                  router={router}
                  hasVehicleProfile={hasVehicleProfile}
                  isAuthenticated={true}
                />
                : <PageLoader />
              }
            </div>
            <div className="padwrapper">
              {!this.state.isWorkshopsLoading ? this.state.workshopsData && this.state.workshopsData.length > 0 &&
                <Workshops
                  title="Explore Workshops"
                  workshopsData={this.state.workshopsData}
                  router={router}
                />
                : <PageLoader />
              }
            </div>
            <div className="bottomSection">
              <div className="padwrapper">
                {/*Deals*/}


                <Deals
                  router={router}
                />


              </div>
            </div>

            <CreateRequestPopup router={router} modalStatus={this.state.bookServiceModalVisible} hideModalCallback={() => { this.setState({ 'bookServiceModalVisible': false }) }} />
          </div>
          <div className="footerSection footerpad dashboard-footer">
            <Footer regularFooter={true} router={this.props.router} actions={this.props.actions} />
          </div>
        </div>

        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
        </div>}
      </div>
    );
  }
}
