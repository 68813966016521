import React, { Component } from 'react';
import Dropdown from '../common/Dropdown';
import Search from '../common/Search';
import ToastMessage from '../common/ToastMessage';
import MobileSearch from '../common/MobileSearch'
import MessagesDropDownView from '../common/MessagesDropDownView';
import NotificationView from '../common/NotificationView';
import io from 'socket.io-client';
import { BASE_URL } from '../../constants/api';
import { imageBasePath, appBasePath, metaTagData, canonicalLink } from '../../constants/staticData';
import { Helmet } from "react-helmet";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.savedLocation = [
      {
        address: "kr market",
        name: "home"
      },
      {
        address: "551, Mg Road",
        name: "work"
      }, {
        address: "mysore road"
      }
    ];
    this.dropdownList = ["Audi", "Renault", "BMW", "Benz", "Maruthi", "Tesla"];
    this.state = {
      messageSelected: false,
      mobileSearch: false,
    };
  }

  componentWillMount() {
    const that = this;
    if (this.props.authReducer && !this.props.authReducer.loadedAccountStatus) {
      this.props.actions.getAccountStatus();
    }
    if (localStorage.accessToken && !window.socket) {
      window.socket = io.connect(BASE_URL, { 'query': 'token=' + localStorage.accessToken, reconnection: true, reconnectionDelay: 1000, reconnectionDelayMax: 5000, reconnectionAttempts: 99999 });
      window.socket.on('New Job Quotes', function (data) {
        that.props.actions.newSocketNotification(data, 'New Job Quotes');
        that.props.actions.updateBookingRequestNotification(data, "New Job Quotes");
      });

      window.socket.on('job-message', function (data) {
        console.log('Received job-message: ', data);
        that.props.actions.receiveNewMessage(data, data.msg.job_chatroom_id);
      });

      window.socket.on("New Job Quote", function (data) {
        that.props.actions.newSocketNotification(data, "New Job Quotes");
        if (data && data.bookingId) {
          that.props.actions.updateBookingRequestNotification(data, "New Job Quotes");
        }
      });

      //Job Confirmation Notification "Job Accepted"
      window.socket.on("Job Accepted", function (data) {
        let notificationData = data;
        // notificationData.bookingId = data.requestId;
        that.props.actions.newSocketNotification(notificationData, "Job Accepted");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "Job Accepted");
        }
      });

      //Confirmed Job Decline Notification
      window.socket.on("Confirmed Job Decline", function (data) {
        let notificationData = data;
        // notificationData.bookingId = data.requestId;
        that.props.actions.newSocketNotification(notificationData, "Confirmed Job Decline");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "Confirmed Job Decline");
        }
      });

      //Job Quote Edit Notification "Job Quote Edit"
      window.socket.on("Job Quote Edit", function (data) {
        console.log("Job Quote Edit data = ", data);
        let notiData = data;
        if(notiData.data) {
          Object.assign(notiData, notiData.data);
        }
        that.props.actions.newSocketNotification(notiData, "Job Quote Edit");
        if (data && data.bookingId) {
          that.props.actions.updateBookingRequestNotification(notiData, "Job Quote Edit");
        }
      });

      //Job Request Expired "Request Expired"
      window.socket.on("Request Expired", function (data) {
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "Request Expired");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "Request Expired");
        }
      });

      //New Message Notification "Request Completed"
      window.socket.on("Request Completed", function (data) {
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "Request Completed");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "Request Completed");
        }
      });


      //New Message Notification "Service Status Update"
      window.socket.on("Service Status Update", function (data) {
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "Service Status Update");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "Service Status Update");
        }
      });


      //New Message Notification "Service Status Update"
      window.socket.on("New Package Added", function (data) {
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "New Package Added");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "New Package Added");
        }
      });


      //Comment On Review Notifications "Comment On Review"
      window.socket.on("Comment On Review", function (data) {
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "Comment On Review");
      });


      //New payment Notifications "Payment Status"
      window.socket.on("Payment Status", function (data) {
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "Payment Status");
      });

      //New payment Notifications "Pay At Shop Confirmed"
      window.socket.on("Pay At Shop Confirmed", function (data) {
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "Pay At Shop Confirmed");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "Pay At Shop Confirmed");
        }
      });

      //Business Event "Request Cancel"
      window.socket.on("Request Cancel", function (data) {
        console.log('Request Cancel Event 😡 😡 😡');
        let notificationData = data.data;
        notificationData.msg = data.msg;
        that.props.actions.newSocketNotification(notificationData, "Request Cancel");
        if (notificationData && notificationData.bookingId) {
          that.props.actions.updateBookingRequestNotification(notificationData, "Request Cancel");
        }
      });


    }
  }

  render() {
    const { actions, router, commonReducer, metaTagKey } = this.props;
    return (
      <div className={this.props.customClass ? `header-section navbar-fixed-top ${this.props.customClass}` : "header-section navbar-fixed-top"}>
        {metaTagData ? <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTagData[metaTagKey] && metaTagData[metaTagKey].title ? metaTagData[metaTagKey].title : 'Carcility'}</title>
          <meta name="description" content={metaTagData[metaTagKey] && metaTagData[metaTagKey].description ? metaTagData[metaTagKey].description : ''} />
          <link rel="canonical" href={canonicalLink} />
        </Helmet> : ''}
        <div className="logo-section page-logo-section">
          <a onClick={(e) => { e.preventDefault(); this.props.router.push(`${appBasePath}/`); }}>
            <img src={`${imageBasePath}/images/logo.svg`}  alt="Car service in Dubai,car repair , car maintenance ,carcility-logo" className="logo-img" />
          </a>
        </div>
        <div className="header-search">
          <Search savedLocation={this.savedLocation} actions={actions} commonReducer={commonReducer} dropdownList={this.dropdownList} />
        </div>
        <div className="mobile-search">
          {this.state.mobileSearch && <MobileSearch
            dropdownList={this.dropdownList}
            savedLocation={this.savedLocation}
            callBackBtn={() => this.setState({ mobileSearch: false })}
          />}
        </div>
        <div className="header-right">
          <ul className="list-unstyled">
            <li className="search-mobile" onClick={this.props.hideOption}>
              <i className="mdi mdi-magnify" aria-hidden="true" />
            </li>
            <li className="notification-menu mobile-view" onClick={() => { this.props.router.push(`${appBasePath}/notification`) }}>
              <label>
                <i className="mdi mdi-bell" aria-hidden="true" />
                {commonReducer.totalUnreadAlerts > 0 && <span className="no-notify">
                  <label></label>
                </span>}
              </label>
            </li>

            <NotificationView {...this.props} />
            <MessagesDropDownView {...this.props} />
            <li className="profile-header">
              <Dropdown actions={actions} router={router} />
            </li>
          </ul>
        </div>

        {this.props.bookingRequestReducer && this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType && <ToastMessage actions={this.props.actions} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
      </div>
    );
  }
}
