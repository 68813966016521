import React, { Component } from 'react';
import TextInput from '../common/TextInput';
import { validationMessage, imageBasePath, appBasePath, reCaptchaSiteKey } from '../../constants/staticData';
import Button from '../common/Button';
import AlertDismissable from '../common/AlertDismissable';
import GmapsEmergency from '../Emergency/GmapsEmergency';
import { FormControl } from 'react-bootstrap';
import Header from '../common/Header';
import LoginHeader from '../common/LoginHeader';
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import Footer from '../Landing/Footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { validateFormSubmit } from "../../helpers";



class ContactUs extends Component {
  constructor() {
    super();
    this.state = {
      searchMobile: false,
      notificationVisible: '',
      messageVisible: '',
      reCaptchaError: true,
      messageError: '',
      captchaError: '',
      currentPosition: {
        lat: 25.120381,
        lng: 55.375307,
        pinImage: '../../images//drag-loc.png',
        dragEle: false
      }
    };

    this.errors = {
      name: false,
      email: false,
      mobilenumber: false,
      message: false,
      recaptcha:false
      // 'terms': false
    };
    this.initialFormData = {
      name: '',
      email: '',
      mobilenumber: '',
      message: '',
      recaptcha: ''
    };
    this.formData = {
      ...this.initialFormData
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }
  toggleNotification(isVisible) {
    this.setState({ notificationVisible: isVisible });
  }
  toggleMessage(isVisible) {
    this.setState({ messageVisible: isVisible });
  }

  onFieldChange(value, name) {
    console.log('99999');
    this.setState({ formError: false });
    this.formData[name] = value;
    this.errors[name] = value ? false : true;
  }

  onFieldChangeMessage() {
    let value = document.getElementById('messageData').value;
    this.formData.message = value;
    this.errors['message'] = value ? false : true;
    if(value){
      this.setState({ 'messageError' : ''});
    }else {
      this.setState({ 'messageError' : validationMessage.message.validationError});
    }
  }



  onCaptchaVerified(grecaptcha) {
    if(grecaptcha){
      this.errors['recaptcha'] = false;
      this.formData.recaptcha = 'false';
      this.setState({ 'captchaError' : ''})
    }else{
      this.errors['recaptcha'] = true;
      this.formData.recaptcha = 'true';
      this.setState({ 'captchaError' :validationMessage.captcha.validationError})
    }
  }

  onSubmit() {
    let formData = {
      ...this.formData
    };
    const validatedForm = validateFormSubmit(formData, this.errors);
    this.errors = validatedForm.errors;



    if (!validatedForm.validForm) {
      this.setState({ formError: true });
      if(this.errors['message'])
        this.setState({ 'messageError' : validationMessage.message.validationError})
      if(this.errors['recaptcha'])
        this.setState({ 'captchaError' : validationMessage.captcha.validationError})

      return;

    }else{
      this.props.actions.contactUs(this.formData);
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log('000');
    const statusMessage = nextProps.authReducer.statusMessage;
    if (statusMessage && statusMessage.indexOf('soon') === 66) {
      this.contactFormRef.reset();
      this.formData = {
        ...this.initialFormData
      };
      setTimeout(
        () => {
          this.contactFormRef.querySelectorAll('input,textarea').forEach(function (el) {
            el.value = '';
          });
        },
        0
      );
    }
  }

  render() {
    const { commonReducer, router, actions, authReducer } = this.props;
    const thankYouTxt = <b>Thank you!</b>;
    return (
      <div className="contact-body">
        {localStorage.getItem('authData')
          ? <Header
            hideOption={() => this.setState({ searchMobile: true })}
            commonReducer={commonReducer}
            notificationCount={2}
            profileName="Derrick Frank"
            notificationCallBack={this.toggleNotification}
            messageCallBack={this.toggleMessage.bind(this)}
            router={router}
            actions={actions}
            authReducer={this.props.authReducer}
            metaTagKey={'contact-us'}
          />
          : <LoginHeader
            hideOption={() => this.setState({ searchMobile: true })}
            pageType="signIn"
            headerClickAction={e => {
              e.preventDefault();
              router.push(`${appBasePath}/sign-in`);
            }}
            metaTagKey={'contact-us'}
          />}
        {/* <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
                <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} /> */}
        <div className="faq-banner banner-top">
          <div className="faq-img">
            <img src={`${imageBasePath}/images/contact-carcility-car-services.jpg`} alt="contact carcility car services" />
          </div>
          <div className="faq-desc">
            <h1> Contact Us </h1>
            <p> We'd love to hear from you. </p>
          </div>
        </div>
        <div className="contact-us">
          <div className="row">
            <div className="col-md-6 left">
              <span className="contact-title">Message Us</span>
              <div className="contact-alt">
                {authReducer &&
                  authReducer.showErrorMessage &&
                  <AlertDismissable
                    bsStyle={
                      authReducer.statusMessage.indexOf('soon') === 66
                        ? 'success '
                        : 'danger error-back'
                    }
                    closeLabel="Close alert"
                    closeAction={this.props.actions.hideErrorMessage}
                  >
                    <p>
                      {authReducer.statusMessage.indexOf('soon') === 66 ? thankYouTxt : null}
                      {' '}
                      {authReducer.statusMessage}
                    </p>
                  </AlertDismissable>}
              </div>
              <div className="n-holder">
                <form ref={el => this.contactFormRef = el}>
                  <TextInput
                    type="text"
                    label="Name"
                    name="name"
                    id="userName"
                    showValidationError={this.errors['name']}
                    emptyError={validationMessage.name.emptyError}
                    validationError={validationMessage.name.validationError}
                    onChange={this.onFieldChange.bind(this)}
                  />
                  <TextInput
                    type="email"
                    label="Email"
                    name="email"
                    id="userEmail"
                    showValidationError={this.errors['email']}
                    emptyError={validationMessage.email.emptyError}
                    validationError={validationMessage.email.validationError}
                    onChange={this.onFieldChange.bind(this)}
                  />
                  <TextInput
                    type="phone"
                    label="Mobile Number"
                    name="mobilenumber"
                    id="phone"
                    showValidationError={this.errors['mobilenumber']}
                    emptyError={validationMessage.phone.emptyError}
                    validationError={validationMessage.phone.validationError}
                    onChange={this.onFieldChange.bind(this)}
                  />

                  <FormControl
                    className="textAlign message-textarea"
                    id="messageData"
                    onChange={this.onFieldChangeMessage.bind(this)}
                    componentClass="textarea"
                    placeholder="Message"
                  />
                  <span className="error-text">{ this.state.messageError }</span>

                  <div>
                    <ReCAPTCHA
                      className = "reCaptcha"
                      ref="recaptcha"
                      sitekey = {reCaptchaSiteKey}
                      onChange={this.onCaptchaVerified.bind(this)}
                    />
                    <span className="error-text error-captcha">{ this.state.captchaError }</span>

                  </div>

                </form>
                <Button
                  customClass="submit-contact-btn"
                  fontSize={16}
                  label="Message"
                  btnCallBack={() => this.onSubmit(this)}
                />
              </div>
            </div>
            <div className="col-md-6 right">
              <span className="contact-title">Reach out to Us</span>

              <div className="n-holder">
                <div className="n-sub-holder">
                  <span className="sub-content address">Carcility Technologies FZE</span>
                </div>
                <div className="n-sub-holder">
                  <span className="sub-title">CONTACT</span>
                  <a className="sub-content ph-no" href="tel:+971543040110">+971 54 3040110</a>
                </div>
                <div className="n-sub-holder">
                  <span className="sub-title">LOCATION</span>
                  <span className="sub-content address">Technohub 1,2 <br />Dubai Silicon Oasis<br/> PO Box 342175<br/>Dubai, UAE</span>
                </div>
                <div className="n-sub-holder">
                  <span className="sub-title">EMAIL</span>
                  <a className="sub-content email" href="mailto:info@carcility.com">
                    info@carcility.com
                                    </a>
                </div>
                <div className="n-sub-holder map">
                  <GmapsEmergency
                    center={{
                      lat: Number(25.120381),
                      lng: Number(55.375307)
                    }}
                    zoom={13}
                    marker={{ position: { lat: Number(25.120381), lng: Number(55.375307) } }}
                    pinImage={`${imageBasePath}/images/drag-loc.png`}
                    containerElement={<div style={{ height: 240 + 'px', width: 'auto' }} />}
                    mapElement={<div style={{ height: 240 + 'px', width: 'auto' }} />}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        {this.state.searchMobile &&
          <div>

            <MobileHeaderSearch
              mobile={true}
              actions={this.props.actions}
              commonReducer={this.props.commonReducer}
              mobSearchClick={() => this.setState({ searchMobile: false })}
            />
          </div>}
        <Footer {...this.props} />
      </div>
    );
  }
}

export default ContactUs;
