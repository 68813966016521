import React, { Component } from 'react';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import Gmaps from '../MyRequest/Gmaps';
import Services from './Services';
import { forEach } from "lodash";
import CustomModal from '../common/CustomModal';
import { DropdownButton, MenuItem, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { map, filter, cloneDeep } from "lodash";
import MobileHeaderSearch from '../common/MobileHeaderSearch';
import { imageBasePath } from '../../constants/staticData';

class Emergency extends Component {
    constructor(props, context) {
        super(props, context);
        this.toggleNotification = this.toggleNotification.bind(this);
        this.showPosition = this.showPosition.bind(this);
        this.getCurrentLocationFromLatLng = this.getCurrentLocationFromLatLng.bind(this);
        this.updateData = this.updateData.bind(this);
        this.state = {
            showFullPageLoader: false,
            searchMobile:false,
            locationDdStatus: false,
            searchedVal: "",
            locationModal: false,
            setCenter: false,
            mapsCenter: { lat: 25.2048, lng: 55.2708 },
            editClicked: false,
            filteredCarRepairCategories: undefined,
            visibleCategory: undefined,
            notificationVisible: false,
            messageVisible: false,
            mapAddress: '',
            subServices: [],
            currentPosition: {
                lat: 0,
                lng: 0,
                pinImage: `${imageBasePath}/images/map_blue_pointer.png`,
                dragEle: false

            },
            dragPosition: {
                lat: 12.9569939,
                lng: 77.208421,
                pinImage: `${imageBasePath}/images/drag-loc.png`,
                dragEle: true
            },
        };
        this.savedLocation = [
            {
                address: "kr market",
                name: "home"
            },
            {
                address: "551, Mg Road",
                name: "work"
            }, {
                address: "mysore road"
            }
        ]
    }
    componentDidMount() {
        let modal = false;
        //this.getCurrentLocationFromLatLng(this.state.dragPosition);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
            // navigator.geolocation.watchPosition(this.showModal.bind(this),this.showModal.bind(this))
            this.setState({
                showFullPageLoader: true 
            });
        } else {
            alert("Geolocation is not supported by this browser.");
            this.setState({
                showFullPageLoader: false 
            });
        }
        if (!this.props.carProfileReducer.carProfiles) {
            this.props.actions.getCarProfileList(this.props.router);
        }
        let that = this;
        this.props.actions.getAllServices(function (data) {
            that.setState({ subServices: data[3].sub_services });
        });
    }

    showModal(error) {
        if (error.message == "User denied Geolocation") {
            this.setState({ locationModal: true })
        } else {
            this.setState({ locationModal: false })
        }
    }

    showPosition(position) {
        let currentVal = cloneDeep(this.state.currentPosition);
        currentVal.lat = position.coords.latitude;
        currentVal.lng = position.coords.longitude;
        this.setState({
            dragPosition : {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                pinImage: `${imageBasePath}/images/drag-loc.png`,
                dragEle: true
            }
        });
        this.getCurrentLocationFromLatLng(this.state.dragPosition);
        this.setState({
            currentposition: currentVal,
            mapsCenter: currentVal, 
            setCenter: true
        });

    }

    toggleNotification(isVisible) {
        this.setState({ 'notificationVisible': isVisible });
    }

    toggleMessage(isVisible) {
        this.setState({ 'messageVisible': isVisible });
    }
    markerDrag(map) {
        let position = {
            lat: map.latLng.lat(),
            lng: map.latLng.lng(),
            pinImage: `${imageBasePath}/images/drag-loc.png`,
            dragEle: true
        };
        this.getCurrentLocationFromLatLng(position);
        this.setState({ dragPosition: position });
    }

    getCurrentLocationFromLatLng(latlng) {
        console.log('geolocation');
        console.log(latlng, this.mapRef);
        const that = this;
        let geocoder = new google.maps.Geocoder();
        if (geocoder) {
            geocoder.geocode({ location: { 'lat': latlng.lat, 'lng': latlng.lng } }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK && results && results.length) {
                    console.log(results);
                    this.setState({
                    showFullPageLoader: false,
                    mapsCenter: { lat: latlng.lat, lng: latlng.lng }
                    });
                    that.updateData(results[0]);
                }
            });
        }
        else {
            this.setState({
                showFullPageLoader: false,
            });
        }
    }

    updateData(dataresult) {
        this.setState({ mapAddress: dataresult });
    }
    onSearchBoxMounted(searchBox) {
        this._searchBox = searchBox;
    }
    onPlacesChanged() {

        const places = this._searchBox.getPlaces();
        let newDragPosition = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
            pinImage: `${imageBasePath}/images/drag-loc.png`,
        }
        let newCenter = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
        }
        this.getCurrentLocationFromLatLng(newCenter);
        this.setState({ dragPosition: newDragPosition, setCenter: true, mapsCenter: newCenter })
    }
    fixCenter() {
        let CenterVal = { lat: this.state.currentPosition.lat, lng: this.state.currentPosition.lng };
        this.setState({ mapsCenter: CenterVal, setCenter: true })
    }


    render() {
        const { commonReducer, router, actions, bookingRequestReducer } = this.props;
        let allMarkers = [];
        allMarkers.push(this.state.currentPosition);
        allMarkers.push(this.state.dragPosition)
        const jobCardLocation = forEach(allMarkers, (loc) => {
            return {
                lat: loc.latitude, lng: loc.longitude, pinImage: loc.pinImage
            }
        })
        const filteredSavedLocation = filter(this.savedLocation, (location, key) => {
            if (this.state.searchedVal != "" && location.address.indexOf(this.state.searchedVal) != -1) {
                return location;
            } else if (this.state.searchedVal == "") {
                return location;
            }
        });
        return (
            <div className="jobUpdate">
                {/*Header*/}
                <Header hideOption={()=>this.setState({searchMobile:true})}
                    bookingRequestReducer={this.props.bookingRequestReducer}
                    commonReducer={commonReducer} notificationCount={2} profileName="Derrick Frank" notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
                <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
                <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
                <div className="main-wrapper">
                    {/*Sidebar*/}
                    <Sidebar router={this.props.router} />
                    {/*message*/}
                    {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
                    <div className="page-sec-header">
                        <div className="padwrapper">
                            <h4>Roadside Assistance</h4>
                        </div>
                    </div>
                    <div className="inSection emergency">
                        <div className="padwrapper">
                            <div className="maps-container mapSection">
                                <Gmaps
                                    mapDrag={() => { this.setState({ setCenter: false }) }}
                                    setCenter={this.state.setCenter}
                                    center={this.state.mapsCenter}
                                    zoom={9}
                                    ref={ref => this.mapRef = ref}
                                    mapElement={<div className="map-align" style={{ height: `100%`, width: `100%` }} />}
                                    containerElement={<div className="map-wrapper" style={{ height: 100 + '%', width: "1100px" }} />}
                                    markers={jobCardLocation}
                                    markerDrag={this.markerDrag.bind(this)}
                                    onSearchBoxMounted={this.onSearchBoxMounted.bind(this)}
                                    onPlacesChanged={this.onPlacesChanged.bind(this)}
                                />
                                <span onClick={this.fixCenter.bind(this)} className="current-position"><i className="mdi mdi-crosshairs-gps"></i></span>
                                <Services data={this.state.subServices} carProfileReducer={this.props.carProfileReducer}
                                    address={this.state.mapAddress} mapPosition={this.state.dragPosition}
                                    actions={this.props.actions} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerSection" >
                    <Footer {...this.props}/>
                </div>
                <CustomModal hideModal={() => { this.setState({ locationModal: false }) }} id="locationModal" className="select-location-modal"
                    showModal={this.state.locationModal} footer={true} title="Select Location"
                    cancelText="Go Back" saveText="Confirm">
                    <Modal.Body>
                        <DropdownButton open={this.state.locationDdStatus} onToggle={(e) => this.setState({ locationDdStatus: e })} noCaret bsSize="large" title={
                            <InputGroup>
                                <FormControl type="text" placeholder="Seach Location" onChange={(e) => this.setState({ searchedVal: e.target.value, locationDdStatus: true, mapAddress: e.target.value })} />

                                {!this.state.locationDdStatus && <i className="mdi mdi-chevron-down" />}
                                {this.state.locationDdStatus && <i className="mdi mdi-chevron-up" />}
                                <InputGroup.Addon>
                                    <i className="mdi mdi-crosshairs-gps" />
                                </InputGroup.Addon>
                            </InputGroup>
                        } >
                            <MenuItem eventKey="current location" className="new-location">
                                <span className="title">
                                    <i className="mdi mdi-crosshairs-gps"></i>
                                    <span>Current Location</span>
                                </span>
                            </MenuItem>
                            {map(filteredSavedLocation, (location, key) => {
                                return (
                                    <div key={key}>
                                        <MenuItem eventKey={location.name} className={location.name ? "" : "new-location"}>
                                            {location.name && <span className='title'>
                                                <i className="mdi mdi-home-variant"></i>
                                                <span>{location.name}</span>
                                            </span>}
                                            {!location.name && <i className="mdi mdi-map-marker"></i>}
                                            <span className={location.name ? "address" : "only-address"}>
                                                {location.address}
                                                {!location.name && <span className="save-edit">Save</span>}
                                                {location.name && <span className="save-edit">Edit</span>}
                                            </span>
                                        </MenuItem>
                                    </div>
                                )
                            })}

                        </DropdownButton>
                    </Modal.Body>
                </CustomModal>
                {this.state.searchMobile && <div >

                    <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={() => this.setState({ searchMobile: false })} />
                </div>}
            </div>
        );
    }
}

export default Emergency;
